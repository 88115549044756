/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { post } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useValidateFormAccident from "hooks/useValidateFormAccident";
import FormAccident from "components/formAccident/FormAccident";
import FormAccidentCars from "components/formAccident/FormAccidentCars";
import FormAccidentCompensations from "components/formAccident/FormAccidentCompensations";
import FormAccidentInjuries from "components/formAccident/FormAccidentInjuries";
import FormAccidentBuildings from "components/formAccident/FormAccidentBuildings";
import FormAccidentOthers from "components/formAccident/FormAccidentOthers";
import { OCCURRENCE_TYPE } from "constants/accident";
import moment from "moment";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { regexNameFullWidth, checkTopLevelDomailEmail } from "utils/regex";
import { Grid } from "@mui/material";
import AccidentLayout from "../components/AccidentLayout";

function AccidentInfomation() {
  const { t } = useTranslation();
  const { validationSchema, defaultValues } = useValidateFormAccident();
  const [fileList, setFileList] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const validationMoreAccidentInfomation = Yup.object().shape({
    nameContractSigning: Yup.string()
      .test("", t("validate.E003"), (value) => {
        if (!value) {
          return true;
        }
        if (!value.trim().length) {
          return false;
        }
        return regexNameFullWidth.test(value);
      })
      .max(50, t("validate.E003")),
    constractEmail: Yup.string()
      .email(t("validate.E006"))
      .test("", t("validate.E006"), (value) => {
        if (!value) {
          return true;
        }
        return checkTopLevelDomailEmail(value.trim());
      })
      .max(50, t("validate.E007")),
  });

  const defaultValuesMoreAccidentInfomation = {
    nameContractSigning: "",
    constractEmail: "",
  };

  const resultValidate = validationSchema.concat(validationMoreAccidentInfomation);
  const resultDefaultValues = { ...defaultValues, ...defaultValuesMoreAccidentInfomation };

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const { control, handleSubmit, watch, setValue, reset, clearErrors } = useForm({
    defaultValues: resultDefaultValues,

    mode: "onChange",
    resolver: yupResolver(resultValidate),
  });

  const postAccidentAPI = async (data) => post("api/accident", data);

  const { mutate: createAccident, isLoading } = useMutation(postAccidentAPI, {
    onSuccess: () => {
      openSuccessSB();
      reset();
      setFileList([]);
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.accidentInfomation");
    return () => {
      document.title = t("title");
    };
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append("reporterName", data.reporterName.trim());
    formData.append("reporterPhoneNumber", data.reporterPhoneNumber);
    formData.append("reporterEmail", data.reporterEmail.trim());
    data.occurrenceTime &&
      formData.append("occurrenceTime", moment(data.occurrenceTime).format("YYYY-MM-DD HH:mm"));
    formData.append("occurrenceType", data.occurrenceType);
    formData.append("nameSigning", data.nameSigning.trim());
    data.nameContractSigning &&
      formData.append("nameContractSigning", data.nameContractSigning.trim());
    data.constractPhoneNumber && formData.append("constractPhoneNumber", data.constractPhoneNumber);
    data.constractEmail && formData.append("constractEmail", data.constractEmail.trim());
    data.address && formData.append("address", data.address);

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.CARS) {
      formData.append("caDriverName", data.caDriverName);
      formData.append("caDriverType", data.caDriverType);
      formData.append("caDriverContract", data.caDriverContract);
      data.caDriverBirthday &&
        formData.append("caDriverBirthday", moment(data.caDriverBirthday).format("YYYY-MM-DD"));
      data.caLicenseExpirationDate &&
        formData.append(
          "caLicenseExpirationDate",
          moment(data.caLicenseExpirationDate).format("YYYY-MM-DD")
        );
      formData.append("caCarNumber", data.caCarNumber);
      data.caVictimDriverName && formData.append("caVictimDriverName", data.caVictimDriverName);
      data.caVictimOtherParty &&
        formData.append("caVictimOtherParty", data.caVictimOtherParty.trim());
      data.caVictimBirthday &&
        formData.append("caVictimBirthday", moment(data.caVictimBirthday).format("YYYY-MM-DD"));
      data.caVictimPhoneNumber && formData.append("caVictimPhoneNumber", data.caVictimPhoneNumber);
      data.caVictimAddress && formData.append("caVictimAddress", data.caVictimAddress);
      data.caVictimCarType && formData.append("caVictimCarType", data.caVictimCarType);
      data.caVictimCarNumber && formData.append("caVictimCarNumber", data.caVictimCarNumber);
      data.caVictimNote && formData.append("caVictimNote", data.caVictimNote);
      formData.append("caAccidentSite", data.caAccidentSite);
      formData.append("caAccidentType", data.caAccidentType);
      formData.append("caAccidentStatus", data.caAccidentStatus);
      formData.append("caAccidentReportPolice", data.caAccidentReportPolice);
      data.caAccidentReportPoliceContent &&
        formData.append("caAccidentReportPoliceContent", data.caAccidentReportPoliceContent);
      data.caAccidentDestination &&
        formData.append("caAccidentDestination", data.caAccidentDestination);
      data.caSpecialNote && formData.append("caSpecialNote", data.caSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.COMPENSATIONS) {
      formData.append("coAccidentSite", data.coAccidentSite);
      data.coVictimName && formData.append("coVictimName", data.coVictimName);
      data.coVictimContract && formData.append("coVictimContract", data.coVictimContract);
      formData.append("coAccidentType", data.coAccidentType);
      data.coVictimJob && formData.append("coVictimJob", data.coVictimJob);
      formData.append("codamageSituation", data.codamageSituation);
      formData.append("coMeasures", data.coMeasures);
      data.coNote && formData.append("coNote", data.coNote);
      data.coSpecialNote && formData.append("coSpecialNote", data.coSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.INJURIES) {
      formData.append("inVictimName", data.inVictimName.trim());
      data.inVictimPosition && formData.append("inVictimPosition", data.inVictimPosition);
      data.inInjuredLocation && formData.append("inInjuredLocation", data.inInjuredLocation);
      formData.append("inAccidentSite", data.inAccidentSite);
      formData.append("inAccidentStatus", data.inAccidentStatus);
      data.inCompensationUsage && formData.append("inCompensationUsage", data.inCompensationUsage);
      data.inSpecialNote && formData.append("inSpecialNote", data.inSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.BUILDINGS) {
      formData.append("buAccidentType", data.buAccidentType);
      formData.append("buAccidentSite", data.buAccidentSite);
      formData.append("buAccidentStatus", data.buAccidentStatus);
      data.buBuildingType && formData.append("buBuildingType", data.buBuildingType);
      data.buMeasures && formData.append("buMeasures", data.buMeasures);
      data.buNote && formData.append("buNote", data.buNote);
      data.buSpecialNote && formData.append("buSpecialNote", data.buSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.OTHERS) {
      formData.append("otAccidentSite", data.otAccidentSite);
      formData.append("otAccidentOccurred", data.otAccidentOccurred);
      formData.append("otAccidentStatus", data.otAccidentStatus);
      data.otNote && formData.append("otNote", data.otNote);
      data.otSpecialNote && formData.append("otSpecialNote", data.otSpecialNote);
    }
    fileList.forEach((file) => {
      formData.append("files", file.originFileObj, file.name);
    });
    createAccident(formData);
  };

  const renderButtonAction = () => (
    <Grid container columnSpacing={3} rowSpacing={2} mt={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <MDBox>
          <MDButton
            variant="outlined"
            color="info"
            fullWidth
            onClick={() => reset()}
            disabled={isLoading}
          >
            {t("accidentInfomation.btn.clear")}
          </MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <MDBox>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
          >
            {t("accidentInfomation.btn.send")}
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );

  return (
    <AccidentLayout>
      <FormAccident
        control={control}
        setValue={setValue}
        clearErrors={clearErrors}
        title={t("accidentInfomation.title")}
        isAccidentInfomation
        disabled={isLoading}
      />
      {watch("occurrenceType") === OCCURRENCE_TYPE.CARS && (
        <FormAccidentCars
          control={control}
          watch={watch}
          renderButtonAction={renderButtonAction}
          fileList={fileList}
          setFileList={setFileList}
          disabled={isLoading}
        />
      )}
      {watch("occurrenceType") === OCCURRENCE_TYPE.COMPENSATIONS && (
        <FormAccidentCompensations
          control={control}
          renderButtonAction={renderButtonAction}
          fileList={fileList}
          setFileList={setFileList}
          disabled={isLoading}
        />
      )}
      {watch("occurrenceType") === OCCURRENCE_TYPE.INJURIES && (
        <FormAccidentInjuries
          control={control}
          renderButtonAction={renderButtonAction}
          fileList={fileList}
          setFileList={setFileList}
          disabled={isLoading}
        />
      )}
      {watch("occurrenceType") === OCCURRENCE_TYPE.BUILDINGS && (
        <FormAccidentBuildings
          control={control}
          renderButtonAction={renderButtonAction}
          fileList={fileList}
          setFileList={setFileList}
          disabled={isLoading}
        />
      )}
      {watch("occurrenceType") === OCCURRENCE_TYPE.OTHERS && (
        <FormAccidentOthers
          control={control}
          renderButtonAction={renderButtonAction}
          fileList={fileList}
          setFileList={setFileList}
          disabled={isLoading}
        />
      )}
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("accidentInfomation.register.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("register.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </AccidentLayout>
  );
}

export default AccidentInfomation;
