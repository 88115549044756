import { useNavigate, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { post } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import InputController from "components/withController/InputController";
import { checkTopLevelDomailEmail, regexPassword, regexNameFullWidth } from "utils/regex";

function CreateUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useLocation();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    password: Yup.string().required(t("validate.E001")).matches(regexPassword, t("validate.E027")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validate.password.match"))
      .required(t("validate.E001")),
    fullName: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const postRegisterUser = async (data) => post("api/register", data);

  const { mutate: registerUser, isLoading } = useMutation(postRegisterUser, {
    onSuccess: () => {
      openSuccessSB();
      setTimeout(() => {
        navigate("/users");
      }, 1000);
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.createUser");
    return () => {
      document.title = t("title");
    };
  }, []);

  const onRegister = (data) => {
    registerUser(data);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} sm={9} md={6} lg={6} xl={6}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form">
                  <InputController
                    name="fullName"
                    control={control}
                    type="text"
                    label={t("lable.name")}
                    textRequired="*"
                    disabled={isLoading}
                  />
                  <InputController
                    name="email"
                    control={control}
                    type="email"
                    label={t("lable.email")}
                    textRequired="*"
                    disabled={isLoading}
                  />
                  <InputController
                    name="password"
                    control={control}
                    type="password"
                    label={t("lable.password")}
                    textRequired="*"
                    disabled={isLoading}
                  />
                  <InputController
                    name="confirmPassword"
                    control={control}
                    type="password"
                    label={t("lable.password.confirm")}
                    textRequired="*"
                    disabled={isLoading}
                  />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSubmit(onRegister)}
                      disabled={isLoading}
                    >
                      {t("createUser.btn.register")}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2} mb={1}>
                    <MDButton
                      variant="outlined"
                      color="info"
                      fullWidth
                      onClick={() => navigate(-1)}
                      disabled={isLoading}
                    >
                      {t("createUser.btn.back")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("createUser.register.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("createUser.register.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default CreateUser;
