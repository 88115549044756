import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import { useMutation } from "@tanstack/react-query";
import { post, setAuthHeader } from "api/BaseRequest";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import queryString from "query-string";
import jwt_decode from "jwt-decode";
import { setCookie, STORAGEKEY } from "utils/storage";
import BasicLayout from "../components/BasicLayout";

function AuthTwitter() {
  const navigate = useNavigate();

  const postLoginUser = async (data) => post("api/login", data);

  const { mutate: loginUser } = useMutation(postLoginUser, {
    onSuccess: (data) => {
      setCookie(STORAGEKEY.ACCESS_TOKEN, data?.data?.accessToken);
      setCookie(STORAGEKEY.REFRESH_TOKEN, data?.data?.refreshToken);
      setAuthHeader(data?.data?.accessToken);
      navigate("/dashboad");
    },
    onError: () => {},
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { search } = window.location;
        const parsed = queryString.parse(search);
        const dataAuthTwitter = jwt_decode(parsed?.token);
        const { isExist, twitterId, fullName } = dataAuthTwitter || {};
        if (isExist && twitterId) {
          loginUser({ twitterId });
        } else {
          navigate("/register", {
            state: { fullName, twitterId },
          });
        }
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchData();
  }, []);
  return (
    <BasicLayout>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}
      >
        <CircularProgress color="info" />
      </Box>
    </BasicLayout>
  );
}

export default AuthTwitter;
