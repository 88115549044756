import jwt_decode from "jwt-decode";
import { getCookie, STORAGEKEY } from "utils/storage";

const useUser = () => {
  const token = getCookie(STORAGEKEY.ACCESS_TOKEN);
  const data = token ? jwt_decode(token) : undefined;
  localStorage.setItem("Hsp_Role", data?.role);
  return { data };
};

export default useUser;
