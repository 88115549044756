import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import masterCardLogo from "assets/images/logos/logo.jpg";
import * as Yup from "yup";
import moment from "moment";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import { post } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useMutation } from "@tanstack/react-query";
import InputController from "components/withController/InputController";
import { checkTopLevelDomailEmail } from "utils/regex";
import BasicLayout from "../components/BasicLayout";

function ForgotPassword() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [second, setSecond] = useState(60);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const openErrorEmail = () => setErrorEmail(true);
  const closeErrorEmail = () => setErrorEmail(false);

  const postSendEmail = async (data) => post("api/send-email", data);

  const { mutate: sendEmail, isLoading } = useMutation(postSendEmail, {
    onSuccess: (data) => {
      // openSuccessSB();
      navigate("/send-email", {
        state: {
          email: data.data?.email,
        },
      });
      localStorage.setItem("emailSended", data.data?.email);
    },
    onError: () => {
      openErrorEmail();
    },
  });

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
  });
  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  useEffect(() => {
    document.title = t("title.forgotPassword");
    return () => {
      document.title = t("title");
    };
  }, []);

  const onNextStep = (data) => {
    const emailSended = localStorage.getItem("emailSended");
    if (emailSended && emailSended === data.email) {
      const timeNow = moment();
      const timeSendMail = localStorage.getItem("timeSendMail");
      const diffInSeconds = timeNow.diff(timeSendMail, "seconds");
      if (diffInSeconds >= 60) {
        localStorage.setItem("timeSendMail", moment());
        sendEmail(data);
      } else {
        setSecond(60 - diffInSeconds);
        openErrorSB();
      }
    } else {
      // localStorage.setItem("emailSended", data.email);
      sendEmail(data);
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          // bgColor="info"
          borderRadius="lg"
          // coloredShadow="success"
          mx={2}
          mt={-4}
          // p={3}
          // mb={1}
          textAlign="center"
        >
          <MDBox
            component="img"
            src={masterCardLogo}
            alt="master card"
            width="100%"
            borderRadius="lg"
            coloredShadow="info"
          />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <InputController
                name="email"
                control={control}
                type="email"
                label={t("lable.email")}
                textRequired="*"
              />
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                disabled={isLoading}
                onClick={handleSubmit(onNextStep)}
              >
                {t("forgotPassword.next")}
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("change.password.success")}
        // dateTime="11 mins ago"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("sendEmail.failed", { second })}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("sendEmail.failed.email")}
        open={errorEmail}
        onClose={closeErrorEmail}
        close={closeErrorEmail}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </BasicLayout>
  );
}

export default ForgotPassword;
