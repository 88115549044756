import axios from "axios";
import { getCookie, setCookie, removeCookie, STORAGEKEY } from "utils/storage";
import jwt_decode from "jwt-decode";
import { ROLE_USER } from "constants/user";

const getUrlPrefix = () => "/";

const options = {
  baseURL: process.env.REACT_APP_BASE_API_URL,
};

const instance = axios.create(options);

instance.interceptors.request.use(
  (config) => config,
  (error) => Promise.reject(error)
);
instance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

const accessToken = getCookie(STORAGEKEY.ACCESS_TOKEN);

if (accessToken) instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;

export const setAuthHeader = (accessToken) => {
  instance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
};

const logOut = () => {
  removeCookie(STORAGEKEY.ACCESS_TOKEN);
  removeCookie(STORAGEKEY.REFRESH_TOKEN);
  const historyRole = localStorage.getItem("Hsp_Role");
  if (+historyRole === ROLE_USER.ADMIN || +historyRole === ROLE_USER.SUB_ADMIN) {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/admin`;
  } else {
    window.location.href = `${process.env.REACT_APP_BASE_API_URL}/login`;
  }
};

const responseHandler = (response) => {
  const { data } = response;
  return data;
};

const errorHandler = async (err) => {
  if (err.response && err.response.status === 401) {
    try {
      const refreshToken = getCookie(STORAGEKEY.REFRESH_TOKEN);
      if (!refreshToken) {
        logOut();
        return;
      }
      const response = await instance.post("/api/refresh-token", { refreshToken });
      const { accessToken } = response.data.data;
      setAuthHeader(accessToken);
      setCookie(STORAGEKEY.ACCESS_TOKEN, accessToken);
    } catch (error) {
      if (error.response && error.response.status === 500) {
        removeCookie(STORAGEKEY.ACCESS_TOKEN);
        removeCookie(STORAGEKEY.REFRESH_TOKEN);
        const historyRole = localStorage.getItem("Hsp_Role");
        if (+historyRole === ROLE_USER.ADMIN || +historyRole === ROLE_USER.SUB_ADMIN) {
          window.location.href = `${process.env.REACT_APP_BASE_API_URL}/admin`;
        } else {
          window.location.href = `${process.env.REACT_APP_BASE_API_URL}/login`;
        }
      }
    }
  }
  throw err;
};

const get = async (url, params = {}) => {
  try {
    const config = { params };
    const response = await instance.get(getUrlPrefix() + url, config);
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

const put = async (url, data = {}) => {
  try {
    let response = {};
    if (data.toLocaleString() === "[object FormData]") {
      response = await instance.put(getUrlPrefix() + url, data);
    } else {
      response = await instance.put(getUrlPrefix() + url, data, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
    }
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

const post = async (url, data = {}) => {
  try {
    const response = await instance.post(getUrlPrefix() + url, data);
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

const del = async (url, data = {}) => {
  try {
    const response = await instance.delete(getUrlPrefix() + url, { data });
    return responseHandler(response);
  } catch (error) {
    return errorHandler(error);
  }
};

export { get, post, del, put };
