import {
  Card,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import DataTable from "examples/Tables/DataTable";
import { useState, useMemo, useEffect } from "react";
import { Controller } from "react-hook-form";
import { get } from "api/BaseRequest";
import { useQuery } from "@tanstack/react-query";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import avatar from "assets/images/default-avatar.jpg";
import MDButton from "components/MDButton";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import CloseIcon from "@mui/icons-material/Close";
import { STATUS_USER } from "constants/user";
import { useTranslation } from "react-i18next";

function SelectController({
  name,
  control,
  setValue,
  clearErrors,
  label,
  option = [],
  textRequired = "",
  disabled = false,
  grid = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
}) {
  // const { columns, rows } = usersTableData();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [optionSelect, setOptionSelect] = useState(option);
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: "",
    status: STATUS_USER.ACTIVE,
  });

  const getList = async () => get("api/users", params);
  const { data, refetch } = useQuery(["getList", { ...params }], getList, {
    keepPreviousData: true,
  });

  const columns = [
    { Header: "ユーザ", accessor: "author", align: "left" },
    { Header: "ステータス", accessor: "status", align: "center" },
  ];

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const rows = useMemo(() => {
    if (data?.data?.length) {
      return data.data?.map((item) => ({
        author: (
          <MDBox
            display="flex"
            alignItems="center"
            lineHeight={1}
            width="345px"
            sx={{
              "&:hover": {
                cursor: "pointer",
              },
            }}
            onClick={() => {
              handleClose();
              setValue(name, item.email);
              clearErrors(name);
            }}
          >
            <MDAvatar src={avatar} name={item?.fullName} size="sm" />
            <MDBox ml={2} lineHeight={1} width="100%">
              <MDTypography display="block" variant="button" fontWeight="medium" width="100%">
                {item?.fullName}
              </MDTypography>
              <MDTypography variant="caption" width="100%">
                {item?.email}
              </MDTypography>
            </MDBox>
          </MDBox>
        ),
        status: (
          <MDBox ml={-1} style={{ minWidth: "102px" }}>
            <MDBadge
              badgeContent={
                item?.status ? t("listUser.status.active") : t("listUser.status.inactive")
              }
              color={item?.status ? "success" : "error"}
              // color="success"
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
      }));
    }
    return [];
  }, [data]);

  return (
    <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
      <MDBox>
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
            <MDBox mb={2}>
              <MDInput
                InputProps={{
                  readOnly: true,
                }}
                InputLabelProps={{
                  style: {
                    paddingTop: "2px",
                  },
                }}
                onClick={handleClickOpen}
                type="text"
                label={
                  <span>
                    {label}
                    {textRequired && <span style={{ color: "red" }}>{textRequired}</span>}
                  </span>
                }
                fullWidth
                error={error}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                disabled={disabled}
              />
              {error?.message && (
                <MDTypography variant="caption" color="error">
                  {error?.message}
                </MDTypography>
              )}
            </MDBox>
          )}
        />
        <Dialog open={open} onClose={handleClose} scroll="body" maxWidth="sm" fullWidth>
          <MDBox sx={{ display: "flex", justifyContent: "flex-end" }}>
            <IconButton onClick={handleClose} color="info" aria-label="close" size="large">
              <CloseIcon />
            </IconButton>
          </MDBox>
          <MDBox pb={3} mx={2}>
            <Grid container spacing={6}>
              <Grid item xs={12}>
                <Card>
                  <MDBox
                    mx={2}
                    // mt={-3}
                    py={2}
                    // px={2}
                    variant="gradient"
                    // bgColor="info"
                    // borderRadius="lg"
                    // coloredShadow="info"
                  >
                    <MDBox>
                      <MDInput
                        type="text"
                        label="アカウント/氏名"
                        placeholder="アカウント/氏名"
                        fullWidth
                        InputLabelProps={{
                          style: {
                            paddingTop: "2px",
                          },
                        }}
                        value={params.search}
                        onChange={(e) => setParams({ ...params, search: e.target.value })}
                      />
                    </MDBox>
                  </MDBox>
                  <MDBox>
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                      paginationInfo={data?.paginationInfo}
                      canSearch
                      params={params}
                      setParams={setParams}
                    />
                  </MDBox>
                </Card>
              </Grid>
            </Grid>
          </MDBox>
        </Dialog>
      </MDBox>
    </Grid>
  );
}
export default SelectController;
