/* eslint no-unused-expressions: ["error", { "allowShortCircuit": true }] */
import { useNavigate, useParams } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { post, get, put } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useValidateFormAccident from "hooks/useValidateFormAccident";
import FormAccident from "components/formAccident/FormAccident";
import FormAccidentCars from "components/formAccident/FormAccidentCars";
import FormAccidentCompensations from "components/formAccident/FormAccidentCompensations";
import FormAccidentInjuries from "components/formAccident/FormAccidentInjuries";
import FormAccidentBuildings from "components/formAccident/FormAccidentBuildings";
import FormAccidentOthers from "components/formAccident/FormAccidentOthers";
import { OCCURRENCE_TYPE } from "constants/accident";
import moment from "moment";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { Card, Grid } from "@mui/material";
import Footer from "examples/Footer";
import MDButton from "components/MDButton";
import useUser from "hooks/useUser";
import { ROLE_USER } from "constants/user";
import * as Yup from "yup";
import { regexNameFullWidth, checkTopLevelDomailEmail } from "utils/regex";

const DetailAccident = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: dataUser } = useUser();
  const params = useParams();

  const [fileList, setFileList] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const { validationSchema, defaultValues } = useValidateFormAccident();
  const validationMoreAnken = Yup.object().shape({
    nameContractSigning: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
    constractEmail: Yup.string()
      .required(t("validate.E001"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
  });

  const resultValidate = validationSchema.concat(validationMoreAnken);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const { control, handleSubmit, watch, setValue, reset, clearErrors, setError } = useForm({
    defaultValues,
    mode: "onChange",
    resolver: yupResolver(resultValidate),
  });

  const getDetailAccident = async () => get(`api/accident/${params.id}`);
  const putAccidentAPI = async (data) => put(`api/accident/${params.id}`, data);

  const { data: dataAccident } = useQuery(["getDetailAccident"], getDetailAccident, {
    keepPreviousData: true,
  });
  const { mutate: updateAccident, isLoading: isLoadingUpdate } = useMutation(putAccidentAPI, {
    onSuccess: (data) => {
      openSuccessSB();
      setTimeout(() => {
        if (data.data.isAnken) {
          navigate("/anken");
        } else {
          navigate(-1);
        }
      }, 1000);
    },
    onError: (err) => {
      if (err?.response?.data?.message === "Invalid email") {
        setError("constractEmail", { type: "custom", message: "メールアドレスが存在しません。" });
      } else {
        openErrorSB();
      }
    },
  });

  useEffect(() => {
    document.title = t("title.detailAccident");
    return () => {
      document.title = t("title");
    };
  }, []);

  useEffect(() => {
    if (dataAccident) {
      const { data } = dataAccident;
      const { dataOccurrenceType } = data;
      reset({
        ...data,
        ...dataOccurrenceType,
        nameContractSigning: data.nameContractSigning || "",
        constractPhoneNumber: data.constractPhoneNumber || "",
        constractEmail: data.constractEmail || "",
        address: data.address || "",
        reciprocalStaff: data.reciprocalStaff || "",
        occurrenceTime: data.occurrenceTime
          ? moment(data.occurrenceTime).utcOffset("+09:00")
          : null,

        // t_accident_cars
        caVictimDriverName: dataOccurrenceType.caVictimDriverName || "",
        caVictimPhoneNumber: dataOccurrenceType.caVictimPhoneNumber || "",
        caVictimOtherParty: dataOccurrenceType.caVictimOtherParty || "",
        caVictimAddress: dataOccurrenceType.caVictimAddress || "",
        caVictimCarType: dataOccurrenceType.caVictimCarType || "",
        caVictimCarNumber: dataOccurrenceType.caVictimCarNumber || "",
        caVictimNote: dataOccurrenceType.caVictimNote || "",
        caAccidentReportPoliceContent: dataOccurrenceType.caAccidentReportPoliceContent || "",
        caAccidentDestination: dataOccurrenceType.caAccidentDestination || "",
        caDriverBirthday: dataOccurrenceType?.caDriverBirthday
          ? moment(dataOccurrenceType.caDriverBirthday).utcOffset("+09:00")
          : null,
        caLicenseExpirationDate: dataOccurrenceType?.caLicenseExpirationDate
          ? moment(dataOccurrenceType.caLicenseExpirationDate).utcOffset("+09:00")
          : null,
        caVictimBirthday: dataOccurrenceType?.caVictimBirthday
          ? moment(dataOccurrenceType.caVictimBirthday).utcOffset("+09:00")
          : null,
        caSpecialNote: dataOccurrenceType.caSpecialNote || "",

        // t_accident_compensations
        coVictimName: dataOccurrenceType.coVictimName || "",
        coVictimJob: dataOccurrenceType.coVictimJob || "",
        coVictimContract: dataOccurrenceType.coVictimContract || "",
        coMeasures: dataOccurrenceType.coMeasures || "",
        coNote: dataOccurrenceType.coNote || "",
        coSpecialNote: dataOccurrenceType.coSpecialNote || "",

        // t_accident_injuries
        inVictimPosition: dataOccurrenceType.inVictimPosition || "",
        inInjuredLocation: dataOccurrenceType.inInjuredLocation || "",
        inCompensationUsage: dataOccurrenceType.inCompensationUsage || "",
        inSpecialNote: dataOccurrenceType.inSpecialNote || "",

        // t_accident_buildings
        buBuildingType: dataOccurrenceType.buBuildingType || "",
        buMeasures: dataOccurrenceType.buMeasures || "",
        buNote: dataOccurrenceType.buNote || "",
        buSpecialNote: dataOccurrenceType.buSpecialNote || "",

        // t_accident_others
        otNote: dataOccurrenceType.otNote || "",
        otSpecialNote: dataOccurrenceType.otSpecialNote || "",
      });
      setFileList(
        JSON.parse(
          dataOccurrenceType?.caFiles ||
            dataOccurrenceType?.coFiles ||
            dataOccurrenceType?.inFiles ||
            dataOccurrenceType?.buFiles ||
            dataOccurrenceType?.otFiles
        )?.map((file) => ({
          name: file.substring(file.lastIndexOf("/") + 1),
          url: file,
          urlFile: true,
        }))
      );
    }
  }, [dataAccident]);

  const onSubmit = (params) => {
    const { data, isAnken } = params;
    const formData = new FormData();
    formData.append("reporterName", data.reporterName.trim());
    formData.append("reporterPhoneNumber", data.reporterPhoneNumber);
    formData.append("reporterEmail", data.reporterEmail.trim());
    data.occurrenceTime &&
      formData.append("occurrenceTime", moment(data.occurrenceTime).format("YYYY-MM-DD HH:mm"));
    formData.append("occurrenceType", data.occurrenceType);
    formData.append("nameSigning", data.nameSigning.trim());
    formData.append("nameContractSigning", data.nameContractSigning.trim());
    data.constractPhoneNumber && formData.append("constractPhoneNumber", data.constractPhoneNumber);
    formData.append("constractEmail", data.constractEmail);
    data.address && formData.append("address", data.address);
    data.reciprocalStaff && formData.append("reciprocalStaff", data.reciprocalStaff);
    formData.append("isAnken", isAnken);

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.CARS) {
      formData.append("caDriverName", data.caDriverName);
      formData.append("caDriverType", data.caDriverType);
      formData.append("caDriverContract", data.caDriverContract);
      data.caDriverBirthday &&
        formData.append("caDriverBirthday", moment(data.caDriverBirthday).format("YYYY-MM-DD"));
      data.caLicenseExpirationDate &&
        formData.append(
          "caLicenseExpirationDate",
          moment(data.caLicenseExpirationDate).format("YYYY-MM-DD")
        );
      formData.append("caCarNumber", data.caCarNumber);
      data.caVictimDriverName && formData.append("caVictimDriverName", data.caVictimDriverName);
      data.caVictimOtherParty &&
        formData.append("caVictimOtherParty", data.caVictimOtherParty.trim());
      data.caVictimBirthday &&
        formData.append("caVictimBirthday", moment(data.caVictimBirthday).format("YYYY-MM-DD"));
      data.caVictimPhoneNumber && formData.append("caVictimPhoneNumber", data.caVictimPhoneNumber);
      data.caVictimAddress && formData.append("caVictimAddress", data.caVictimAddress);
      data.caVictimCarType && formData.append("caVictimCarType", data.caVictimCarType);
      data.caVictimCarNumber && formData.append("caVictimCarNumber", data.caVictimCarNumber);
      data.caVictimNote && formData.append("caVictimNote", data.caVictimNote);
      formData.append("caAccidentSite", data.caAccidentSite);
      formData.append("caAccidentType", data.caAccidentType);
      formData.append("caAccidentStatus", data.caAccidentStatus);
      formData.append("caAccidentReportPolice", data.caAccidentReportPolice);
      data.caAccidentReportPoliceContent &&
        formData.append("caAccidentReportPoliceContent", data.caAccidentReportPoliceContent);
      data.caAccidentDestination &&
        formData.append("caAccidentDestination", data.caAccidentDestination);
      data.caSpecialNote && formData.append("caSpecialNote", data.caSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.COMPENSATIONS) {
      formData.append("coAccidentSite", data.coAccidentSite);
      data.coVictimName && formData.append("coVictimName", data.coVictimName);
      data.coVictimContract && formData.append("coVictimContract", data.coVictimContract);
      formData.append("coAccidentType", data.coAccidentType);
      data.coVictimJob && formData.append("coVictimJob", data.coVictimJob);
      formData.append("codamageSituation", data.codamageSituation);
      formData.append("coMeasures", data.coMeasures);
      data.coNote && formData.append("coNote", data.coNote);
      data.coSpecialNote && formData.append("coSpecialNote", data.coSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.INJURIES) {
      formData.append("inVictimName", data.inVictimName.trim());
      data.inVictimPosition && formData.append("inVictimPosition", data.inVictimPosition);
      data.inInjuredLocation && formData.append("inInjuredLocation", data.inInjuredLocation);
      formData.append("inAccidentSite", data.inAccidentSite);
      formData.append("inAccidentStatus", data.inAccidentStatus);
      data.inCompensationUsage && formData.append("inCompensationUsage", data.inCompensationUsage);
      data.inSpecialNote && formData.append("inSpecialNote", data.inSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.BUILDINGS) {
      formData.append("buAccidentType", data.buAccidentType);
      formData.append("buAccidentSite", data.buAccidentSite);
      formData.append("buAccidentStatus", data.buAccidentStatus);
      data.buBuildingType && formData.append("buBuildingType", data.buBuildingType);
      data.buMeasures && formData.append("buMeasures", data.buMeasures);
      data.buNote && formData.append("buNote", data.buNote);
      data.buSpecialNote && formData.append("buSpecialNote", data.buSpecialNote);
    }

    if (Number(data.occurrenceType) === OCCURRENCE_TYPE.OTHERS) {
      formData.append("otAccidentSite", data.otAccidentSite);
      formData.append("otAccidentOccurred", data.otAccidentOccurred);
      formData.append("otAccidentStatus", data.otAccidentStatus);
      data.otNote && formData.append("otNote", data.otNote);
      data.otSpecialNote && formData.append("otSpecialNote", data.otSpecialNote);
    }

    formData.append(
      "filesUrl",
      JSON.stringify(fileList.filter((i) => i?.urlFile).map((i) => i?.url))
    );
    fileList
      .filter((i) => !i?.urlFile)
      .forEach((file) => {
        formData.append("files", file.originFileObj, file.name);
      });

    updateAccident(formData);
  };

  const renderButtonAction = () => (
    <Grid container columnSpacing={3} rowSpacing={2} mt={1}>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <MDBox>
          <MDButton
            variant="gradient"
            color="info"
            fullWidth
            onClick={handleSubmit((data) => onSubmit({ data, isAnken: 0 }))}
            disabled={isLoadingUpdate || dataUser?.role === ROLE_USER.SUB_ADMIN}
          >
            {t("detailAccident.btn.update")}
          </MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
        <MDBox>
          <MDButton
            variant="outlined"
            color="info"
            fullWidth
            onClick={handleSubmit((data) => onSubmit({ data, isAnken: 1 }))}
            disabled={isLoadingUpdate || dataUser?.role === ROLE_USER.SUB_ADMIN}
          >
            {t("detailAccident.btn.update.anken")}
          </MDButton>
        </MDBox>
      </Grid>
    </Grid>
  );
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <FormAccident
              control={control}
              setValue={setValue}
              clearErrors={clearErrors}
              title={t("detailAccident.title")}
              isDetailAccident
              isSubAdmin={dataUser?.role === ROLE_USER.SUB_ADMIN}
              disabled={isLoadingUpdate}
            />
            {watch("occurrenceType") === OCCURRENCE_TYPE.CARS && (
              <FormAccidentCars
                control={control}
                watch={watch}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                isSubAdmin={dataUser?.role === ROLE_USER.SUB_ADMIN}
                disabled={isLoadingUpdate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.COMPENSATIONS && (
              <FormAccidentCompensations
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                isSubAdmin={dataUser?.role === ROLE_USER.SUB_ADMIN}
                disabled={isLoadingUpdate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.INJURIES && (
              <FormAccidentInjuries
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                isSubAdmin={dataUser?.role === ROLE_USER.SUB_ADMIN}
                disabled={isLoadingUpdate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.BUILDINGS && (
              <FormAccidentBuildings
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                isSubAdmin={dataUser?.role === ROLE_USER.SUB_ADMIN}
                disabled={isLoadingUpdate}
              />
            )}
            {watch("occurrenceType") === OCCURRENCE_TYPE.OTHERS && (
              <FormAccidentOthers
                control={control}
                fileList={fileList}
                setFileList={setFileList}
                renderButtonAction={renderButtonAction}
                isSubAdmin={dataUser?.role === ROLE_USER.SUB_ADMIN}
                disabled={isLoadingUpdate}
              />
            )}
            <MDSnackbar
              color="success"
              icon="check"
              title={t("success")}
              content={t("register.success")}
              open={successSB}
              onClose={closeSuccessSB}
              close={closeSuccessSB}
              bgWhite
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            />
            <MDSnackbar
              color="error"
              icon="warning"
              title={t("failed")}
              content={t("register.failed")}
              open={errorSB}
              onClose={closeErrorSB}
              close={closeErrorSB}
              bgWhite
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            />
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
};

export default DetailAccident;
