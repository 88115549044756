import { Link, useLocation, matchPath } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import routes from "routes";

function Breadcrumbs({ icon, title, route, light }) {
  const { pathname } = useLocation();

  const getBreadcrumb = () => {
    const currentRouter = routes.find((route) =>
      route.path ? matchPath(route.path, pathname) : undefined
    );

    if (currentRouter) {
      return currentRouter.breadCrumb?.map((crumb, id) => (
        <Link to={crumb.link ? crumb.link : "#"} key={crumb.link}>
          <MDTypography
            component="span"
            variant="button"
            fontWeight="bold"
            textTransform="capitalize"
            color={light ? "white" : "dark"}
            // opacity={light ? 0.8 : 0.5}
            opacity={crumb.link ? 0.5 : 1}
            sx={{
              // lineHeight: 0,
              "&:hover": {
                cursor: crumb.link ? "pointer" : "default",
                // textDecoration: crumb.link ? "underline" : "none",
                textUnderlineOffset: "4px",
              },
            }}
          >
            {crumb.label}
          </MDTypography>
        </Link>
      ));
    }
    return null;
  };

  return (
    <MDBox mr={{ xs: 0, xl: 8 }}>
      <MuiBreadcrumbs
        sx={{
          "& .MuiBreadcrumbs-separator": {
            color: ({ palette: { white, grey } }) => (light ? white.main : grey[600]),
          },
        }}
      >
        <Link to="/dashboard">
          <MDTypography
            component="span"
            variant="body2"
            color={light ? "white" : "dark"}
            opacity={light ? 0.8 : 0.5}
            sx={{ lineHeight: 0 }}
          >
            <Icon>{icon}</Icon>
          </MDTypography>
        </Link>
        {getBreadcrumb()}
        {/* {routes.map((el) => (
          <MDTypography to={`/${el}`} key={el}>
            <MDTypography
              component="span"
              variant="button"
              fontWeight="regular"
              textTransform="capitalize"
              color={light ? "white" : "dark"}
              opacity={light ? 0.8 : 0.5}
              sx={{ lineHeight: 0 }}
            >
              {textBreadcrumbs[el]}
            </MDTypography>
          </MDTypography>
        ))} */}
        {/* <MDTypography
          variant="button"
          fontWeight="regular"
          textTransform="capitalize"
          color={light ? "white" : "dark"}
          sx={{ lineHeight: 0 }}
        >
          {textBreadcrumbs[title] || title.replace("-", " ")}
        </MDTypography> */}
      </MuiBreadcrumbs>
      {/* <MDTypography
        fontWeight="bold"
        textTransform="capitalize"
        variant="h6"
        color={light ? "white" : "dark"}
        noWrap
      >
        {title.replace("-", " ")}
      </MDTypography> */}
    </MDBox>
  );
}

// Setting default values for the props of Breadcrumbs
Breadcrumbs.defaultProps = {
  light: false,
};

// Typechecking props for the Breadcrumbs
Breadcrumbs.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
  light: PropTypes.bool,
};

export default Breadcrumbs;
