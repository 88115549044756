export const OCCURRENCE_TYPE = {
  CARS: 1,
  COMPENSATIONS: 2,
  INJURIES: 3,
  BUILDINGS: 4,
  OTHERS: 5,
};
export const TEXT_OCCURRENCE_TYPE = {
  1: "自動車事故",
  2: "賠償事故・建築瑕疵",
  3: "ケガ・疾病",
  4: "建物または設備・什器の事故",
  5: "その他事故",
};
export const TEXT_INSURANCE_TYPE = {
  1: "自動車保険",
  2: "賠償責任保険",
  3: "傷害保険",
  4: "疾病保険",
  5: "動産総合保険",
  6: "取引信用保険",
  7: "建設工事保険",
  8: "火災保険",
  9: "E&O保険",
  10: "その他",
};
export const TEXT_STATUS = {
  1: "保険会社対応中",
  2: "弁護士対応中",
  3: "査定中",
  4: "査定完了",
  5: "解決",
  6: "請求なし",
};
