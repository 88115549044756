export const regexPassword = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/;
// export const regexPhoneNumber = /^(\d{3})-(\d{4})-(\d{4})$/;

export const regexPhoneNumber = /^\d{10,11}$/;

// export const regexNameFullWidth = /^[^\u0000-\u007F]*$/;
// export const regexNameFullWidth = /[^\u0000-\u00FF]/;
export const regexNameFullWidth = /^[\u3000\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF\uff01-\uff5e]+$/;
// \u3000: Fullwidth space
// \u3040-\u309F: Hiragana
// \u30A0-\u30FF: Katakana
// \u4E00-\u9FAF: Kanji (Chinese characters used in Japanese)
// \uff01-\uff5e: Fullwidth ASCII symbols and punctuation marks (including !, @, #, $, %, ^, &, *, (, ), _, +, -, =, {, }, |, [, ], , :, ;, ", ', <, >, ?, /, `, ~)
// \uff61-\uff9f: Halfwidth Katakana (small versions of Katakana characters)

export const regexAddress = /^[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAFＡ-Ｚａ-ｚ０-９－・]+$/;
// \u3040-\u309F - Hiragana characters
// \u30A0-\u30FF - Katakana characters
// \u4E00-\u9FAF - Kanji characters
// Ａ-Ｚａ-ｚ０-９- Full-width numbers
// \u3000 - Space
// －・ - Special characters

export const checkTopLevelDomailEmail = (value) => {
  const topLevelDomain = value?.split(".").pop();
  return topLevelDomain?.length > 1;
};
