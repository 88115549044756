import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useMemo, useState, useEffect } from "react";
import { get, del } from "api/BaseRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import MDSnackbar from "components/MDSnackbar";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { TEXT_OCCURRENCE_TYPE, TEXT_INSURANCE_TYPE, TEXT_STATUS } from "constants/accident";
import { ROLE_USER } from "constants/user";
import useUser from "hooks/useUser";
import InputSearchController from "components/withController/InputSearchController";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DialogConfirm from "components/dialog/DialogConfirm";
import DateRangePickerController from "components/withController/DateRangePickerController";
import InputController from "components/withController/InputController";
import SelectSearchController from "components/withController/SelectSearchController";

const TextRow = ({ text, style }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1} style={style}>
    <MDBox lineHeight={1}>
      <MDTypography
        variant="caption"
        // fontWeight="medium"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontWeight: "bold",
          // color: "red",
        }}
      >
        {text}
      </MDTypography>
    </MDBox>
  </MDBox>
);
const LinkRow = ({ text, link, style }) => (
  <Link to={link}>
    <MDBox display="flex" alignItems="center" lineHeight={1} style={style}>
      <MDBox lineHeight={1}>
        <MDTypography
          variant="caption"
          fontWeight="medium"
          sx={{
            "&:hover": {
              cursor: "pointer",
              textDecoration: "underline",
            },
            display: "-webkit-box",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            fontWeight: "bold",
          }}
        >
          {text}
        </MDTypography>
      </MDBox>
    </MDBox>
  </Link>
);

function ListAken() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: dataUser } = useUser();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: "",
    startDate: null,
    endDate: null,
    insuranceType: "",
    status: "",
  });
  const [rowUser, setRowUser] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [errorDate, setErrorDate] = useState(false);

  const validationSchema = Yup.object().shape({
    search: Yup.string().max(255, t("validate.E015")),
  });

  const { control, handleSubmit, watch, reset } = useForm({
    defaultValues: {
      search: "",
      startDate: null,
      endDate: null,
      insuranceType: "",
      status: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const getListAnken = async () => get("api/anken", params);
  const deleteAnkenAPI = async (id) => del(`api/anken/${id}`);

  const { data, refetch, isFetching } = useQuery(["getListAnken", { ...params }], getListAnken, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });

  const { mutate: deleteAnken, isLoading } = useMutation(deleteAnkenAPI, {
    onSuccess: (data) => {
      refetch();
      setOpenDialog(false);
      openSuccessSB();
    },
    onError: () => {
      setOpenDialog(false);
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.listAken");
    return () => {
      document.title = t("title");
    };
  }, []);

  const columns = [
    { Header: "No", accessor: "no", width: "24px", align: "left" },
    {
      Header: t("listAken.occurrenceTime"),
      accessor: "occurrenceTime",
      align: "left",
    },
    {
      Header: t("listAken.occurrenceType"),
      accessor: "occurrenceType",
      align: "left",
    },
    {
      Header: t("listAken.insuranceType"),
      accessor: "insuranceType",
      align: "left",
    },
    { Header: t("listAken.reporterName"), accessor: "reporterName", align: "left" },
    { Header: t("listAken.nameSigning"), accessor: "nameSigning", align: "left" },
    { Header: t("listAken.status"), accessor: "status", align: "left" },
    { Header: "", accessor: "action", align: "center" },
  ];

  const rows = useMemo(() => {
    if (data?.data?.length) {
      return data.data?.map((item, index) => ({
        no: (
          <Link to={`/anken/detail/${item.id}`}>
            <MDTypography
              variant="caption"
              fontWeight="medium"
              sx={{
                "&:hover": { cursor: "pointer", textDecoration: "underline" },
              }}
            >
              {Number(data?.paginationInfo?.currentPage) * Number(data?.paginationInfo?.perPage) -
                (Number(data?.paginationInfo?.perPage) - index) +
                1}
            </MDTypography>
          </Link>
        ),
        occurrenceTime: (
          <TextRow
            text={
              item?.occurrenceTime
                ? moment(item?.occurrenceTime).utcOffset("+09:00").format("YYYY/MM/DD HH:mm")
                : ""
            }
            style={{ minWidth: "72px" }}
          />
        ),
        occurrenceType: (
          <TextRow text={TEXT_OCCURRENCE_TYPE[item?.occurrenceType]} style={{ minWidth: "68px" }} />
        ),
        insuranceType: (
          <TextRow text={TEXT_INSURANCE_TYPE[item?.insuranceType]} style={{ minWidth: "68px" }} />
        ),
        reporterName: <TextRow text={item?.reporterName} style={{ minWidth: "68px" }} />,
        nameSigning:
          dataUser?.role === ROLE_USER.USER ? (
            <TextRow text={item?.nameSigning} style={{ minWidth: "68px" }} />
          ) : (
            <LinkRow
              text={item?.nameSigning}
              link={`/anken/user/${item?.constractUserId}`}
              style={{ minWidth: "68px" }}
            />
          ),
        status: <TextRow text={TEXT_STATUS[item?.status]} style={{ minWidth: "68px" }} />,
        action: (
          <MDBox sx={{ display: "flex", gap: "6px" }}>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={() => navigate(`/anken/detail/${item.id}`)}
            >
              {dataUser?.role === ROLE_USER.USER
                ? t("listAken.btn.infoAnken")
                : t("listAken.btn.detail")}
            </MDButton>
            {(dataUser?.role === ROLE_USER.ADMIN || dataUser?.role === ROLE_USER.SUB_ADMIN) && (
              <>
                <MDButton
                  variant="outlined"
                  color="info"
                  size="small"
                  disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
                  onClick={() => navigate(`/anken/edit/${item.id}`)}
                >
                  {t("listAken.btn.edit")}
                </MDButton>
                <MDButton
                  variant="outlined"
                  color="error"
                  size="small"
                  disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
                  onClick={() => {
                    setRowUser(item);
                    setOpenDialog(true);
                  }}
                >
                  {t("listAken.btn.delete")}
                </MDButton>
              </>
            )}
          </MDBox>
        ),
      }));
    }
    return [];
  }, [data]);

  const onSearch = (data) => {
    setParams({
      ...params,
      page: 1,
      search: data.search,
      startDate: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") : null,
      endDate: data.endDate ? moment(data.endDate).format("YYYY-MM-DD") : null,
      insuranceType: data.insuranceType ? data.insuranceType : "",
      status: data.status ? data.status : "",
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <InputSearchController
              name="search"
              control={control}
              placeholder={t("listAken.placeholder.search")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <DateRangePickerController
              nameStart="startDate"
              nameEnd="endDate"
              control={control}
              watch={watch}
              setErrorDate={setErrorDate}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <SelectSearchController
              name="insuranceType"
              control={control}
              label={t("lable.insuranceType")}
              option={[
                { id: 0, name: "" },
                { id: 1, name: t("lable.insuranceType.1") },
                { id: 2, name: t("lable.insuranceType.2") },
                { id: 3, name: t("lable.insuranceType.3") },
                { id: 4, name: t("lable.insuranceType.4") },
                { id: 5, name: t("lable.insuranceType.5") },
                { id: 6, name: t("lable.insuranceType.6") },
                { id: 7, name: t("lable.insuranceType.7") },
                { id: 8, name: t("lable.insuranceType.8") },
                { id: 9, name: t("lable.insuranceType.9") },
                { id: 10, name: t("lable.insuranceType.10") },
              ]}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={3} lg={2} xl={2}>
            <SelectSearchController
              name="status"
              control={control}
              label={t("lable.status.accident")}
              option={[
                { id: 0, name: "" },
                { id: 1, name: t("lable.status.accident.1") },
                { id: 2, name: t("lable.status.accident.2") },
                { id: 3, name: t("lable.status.accident.3") },
                { id: 4, name: t("lable.status.accident.4") },
                { id: 5, name: t("lable.status.accident.5") },
                { id: 6, name: t("lable.status.accident.6") },
              ]}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <MDButton
              type="submit"
              variant="outlined"
              color="info"
              fullWidth
              style={{ height: "43px" }}
              onClick={handleSubmit(onSearch)}
              disabled={errorDate}
            >
              {t("listAken.btn.search")}
            </MDButton>
          </Grid>
          {(dataUser?.role === ROLE_USER.ADMIN || dataUser?.role === ROLE_USER.SUB_ADMIN) && (
            <>
              <Grid item xs={0} sm={0} md={3} lg={10} xl={10} />
              <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
                <MDButton
                  variant="contained"
                  color="info"
                  fullWidth
                  style={{ height: "43px" }}
                  disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
                  onClick={() => navigate("/anken/create")}
                >
                  {t("listAken.btn.create")}
                </MDButton>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("listAken.title")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries
                  noEndBorder
                  paginationInfo={data?.paginationInfo}
                  canSearch
                  params={params}
                  setParams={setParams}
                  isLoading={isFetching}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <DialogConfirm
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        renderDialogContent={() => (
          <MDTypography variant="subtitle2" align="center">
            {/* {t("listAken.dialog.content")} */}
            {t("text.delete")}
          </MDTypography>
        )}
        renderDialogActions={() => (
          <>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={() => setOpenDialog(false)}
            >
              {t("listAken.btn.no")}
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => deleteAnken(rowUser?.id)}
            >
              {t("listAken.btn.yes")}
            </MDButton>
          </>
        )}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("listAken.delete.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("listAken.delete.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default ListAken;
