import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import InputController from "components/withController/InputController";
import UploadFileController from "components/withController/UploadFileController";
import { useTranslation } from "react-i18next";

function FormAccidentOthers({
  control,
  renderButtonAction,
  fileList,
  setFileList,
  isSubAdmin = false,
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Card sx={{ marginTop: "60px" }}>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {t("title.ot")}
        </MDTypography>
      </MDBox>

      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <Grid container columnSpacing={3}>
            <InputController
              name="otAccidentSite"
              control={control}
              type="text"
              label={t("lable.otAccidentSite")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="otAccidentOccurred"
              control={control}
              type="text"
              label={t("lable.otAccidentOccurred")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="otAccidentStatus"
              control={control}
              type="text"
              label={t("lable.otAccidentStatus")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="otNote"
              control={control}
              type="text"
              label={t("lable.otNote")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="otSpecialNote"
              control={control}
              type="text"
              label={t("text.input.note")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
          </Grid>
          <UploadFileController
            name="otFiles"
            label={t("lable.otFiles")}
            fileList={fileList}
            setFileList={setFileList}
            disabled={isSubAdmin || disabled}
          />
          {renderButtonAction()}
        </MDBox>
      </MDBox>
    </Card>
  );
}
export default FormAccidentOthers;
