import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";
import { makeStyles } from "@mui/styles";
import { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 369,
  },
}));

function SelectSearchController({
  name,
  control,
  label,
  option = [],
  textRequired = "",
  disabled = false,
  grid = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleClose);

    return () => window.removeEventListener("scroll", handleClose);
  }, []);

  return (
    <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
      <MDBox mb={2}>
        <FormControl fullWidth>
          <InputLabel style={{ paddingTop: "2px" }} id={name}>
            {/* {label} */}
            <span>
              {label}
              {textRequired && <span style={{ color: "red" }}>{textRequired}</span>}
            </span>
          </InputLabel>
          <Controller
            control={control}
            name={name}
            render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
              <>
                <Select
                  labelId={name}
                  id={name}
                  value={value}
                  // label={label}
                  label={
                    <span>
                      {label}
                      {textRequired && <span style={{ color: "red" }}>{textRequired}</span>}
                    </span>
                  }
                  onChange={onChange}
                  onBlur={onBlur}
                  sx={{ height: "44px" }}
                  disabled={disabled}
                  MenuProps={{ classes: { paper: classes.menuPaper } }}
                  open={open}
                  onClose={handleClose}
                  onOpen={handleOpen}
                >
                  {option.map((item) => (
                    <MenuItem value={item?.id}>{item?.name}&nbsp;</MenuItem>
                  ))}
                </Select>
                {error?.message && (
                  <MDTypography variant="caption" color="error" style={{ marginTop: "12px" }}>
                    {error?.message}
                  </MDTypography>
                )}
              </>
            )}
          />
        </FormControl>
      </MDBox>
    </Grid>
  );
}
export default SelectSearchController;
