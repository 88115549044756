/**
Comment
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Menu from "@mui/material/Menu";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import { useNavigate } from "react-router-dom";
import { removeCookie, STORAGEKEY, getCookie } from "utils/storage";
import useUser from "hooks/useUser";
import { ROLE_USER } from "constants/user";

function DefaultNavbarMobile({ open, close }) {
  const navigate = useNavigate();
  const auth = getCookie(STORAGEKEY.ACCESS_TOKEN);
  const { data: dataUser } = useUser();
  const { width } = open && open.getBoundingClientRect();

  return (
    <Menu
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      anchorEl={open}
      open={Boolean(open)}
      onClose={close}
      MenuListProps={{ style: { width: `calc(${width}px - 4rem)` } }}
    >
      <MDBox px={0.5}>
        {auth ? (
          <DefaultNavbarLink
            icon="logout_icon"
            name="ログアウト"
            func={() => {
              removeCookie(STORAGEKEY.ACCESS_TOKEN);
              removeCookie(STORAGEKEY.REFRESH_TOKEN);
              if (dataUser?.role === ROLE_USER.USER) {
                navigate("/login");
              } else {
                navigate("/admin");
              }
            }}
          />
        ) : (
          <>
            <DefaultNavbarLink
              icon="account_circle"
              name="新規登録"
              func={() => navigate("/register")}
            />
            <DefaultNavbarLink icon="key" name="ログイン" func={() => navigate("/login")} />
          </>
        )}
      </MDBox>
    </Menu>
  );
}

// Typechecking props for the DefaultNavbarMenu
DefaultNavbarMobile.propTypes = {
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  close: PropTypes.oneOfType([PropTypes.func, PropTypes.bool, PropTypes.object]).isRequired,
};

export default DefaultNavbarMobile;
