/* eslint-disable jsx-a11y/media-has-caption */
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useMemo, useState, useEffect } from "react";
import avatar from "assets/images/default-avatar.jpg";
import MDAvatar from "components/MDAvatar";
import { get, post } from "api/BaseRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import InputController from "components/withController/InputController";
import UploadFileController from "components/withController/UploadFileController";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import useUser from "hooks/useUser";
import { Image } from "antd";
import { IMAGES, VIDEOS } from "constants/fileTypes";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { ROLE_USER } from "constants/user";
import { Pagination, Stack } from "@mui/material";
import { getCookie, STORAGEKEY } from "utils/storage";
import LoadingButton from "@mui/lab/LoadingButton";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import styles from "./style.module.css";

function DetailAnken() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paramsRouter = useParams();
  const { data: dataUser } = useUser();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
  });
  const [fileList, setFileList] = useState([]);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [loadingExportPDF, setLoadingExportPDF] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    content: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .max(255, t("validate.E015")),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      content: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getDetailAnken = async () => get(`api/anken/${paramsRouter.id}`);
  const getCommentsForAnken = async () => get(`api/comment/${paramsRouter?.id}`, params);
  const postComment = async (data) => post(`api/comment`, data);

  const { data: dataAnken } = useQuery(["getDetailAnken"], getDetailAnken, {
    keepPreviousData: true,
    retry: false,
    onError: (error) => {
      navigate("/dashboard");
    },
  });
  const { data: dataComment, refetch } = useQuery(
    ["getCommentsForAnken", { ...params }],
    getCommentsForAnken,
    {
      keepPreviousData: true,
    }
  );
  const { mutate: createComment, isLoading } = useMutation(postComment, {
    onSuccess: (data) => {
      refetch();
      reset();
      setFileList([]);
      // openSuccessSB();
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.detailAnken");
    return () => {
      document.title = t("title");
    };
  }, []);

  const renderComment = useMemo(() => {
    if (dataComment?.data?.length) {
      return dataComment.data?.map((item) => (
        <MDBox
          key={item.id}
          component="li"
          display="flex"
          alignItems="flex-start"
          py={1}
          mb={1}
          style={{ width: "100%" }}
        >
          <MDBox mr={2}>
            <MDAvatar src={avatar} alt="something here" shadow="md" />
          </MDBox>
          <MDBox style={{ overflow: "auto" }}>
            <MDBox
              display="flex"
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              gap="4px"
            >
              <MDTypography variant="button" fontWeight="medium">
                {item.user.fullName}
              </MDTypography>
              <MDTypography variant="caption" color="text">
                {moment(item.createdAt).utcOffset("+09:00").fromNow()}
              </MDTypography>
              <MDTypography
                variant="button"
                color="black"
                component="pre"
                // style={{ wordWrap: "break-word", maxWidth: "100%" }}
                style={{
                  wordWrap: "break-word",
                  display: "block",
                  maxWidth: "100%",
                  whiteSpace: "pre-wrap",
                }}
              >
                {item.content}
              </MDTypography>
              <MDBox
                // display="flex"
                // flexWrap="wrap"
                // gap="16px"
                // width="100.25%"
                // shadow="xl"
                // borderRadius="xl"
                // pt={2}
                className={styles.mediaComment}
              >
                <UploadFileController
                  disabled
                  fileList={JSON.parse(item?.files)?.map((file) => ({
                    name: file.substring(file.lastIndexOf("/") + 1),
                    url: file,
                    urlFile: true,
                  }))}
                  limit={JSON.parse(item?.files)?.length}
                />
                {/* {item?.files &&
                  JSON.parse(item?.files).map((file, index) => {
                    const extension = file.split(".").pop().toLowerCase();
                    if (IMAGES.includes(extension)) {
                      return (
                        <Image
                          key={file}
                          width={234}
                          height={132}
                          src={file}
                          style={{ objectFit: "cover" }}
                        />
                      );
                    }
                    if (VIDEOS.includes(extension)) {
                      return (
                        <video width="234" height="132" controls>
                          <source src={file} type={`video/${extension}`} />
                        </video>
                      );
                    }
                    return (
                      <a href={file} download>
                        <MDBox
                          width={234}
                          height={132}
                          // borderRadius="lg"
                          display="flex"
                          justifyContent="space-between"
                          alignItems="center"
                          p={3}
                          sx={{
                            border: "1px solid #00a5b4",
                          }}
                        >
                          <PictureAsPdfIcon sx={{ fontSize: 60 }} />
                          <MDTypography
                            variant="caption"
                            color="text"
                            sx={{
                              maxWidth: "160px",
                            }}
                          >
                            {file.split("\\").pop().split("/").pop()}
                          </MDTypography>
                        </MDBox>
                      </a>
                    );
                  })} */}
              </MDBox>
            </MDBox>
          </MDBox>
        </MDBox>
      ));
    }
    return (
      <MDTypography
        textTransform="capitalize"
        align="center"
        sx={{ fontSize: "16px", color: "#7B809A" }}
      >
        {t("detailAnken.noComment")}
      </MDTypography>
    );
  }, [dataComment]);

  const onSubmit = (data) => {
    if (dataAnken && dataUser) {
      const formData = new FormData();
      formData.append("userId", dataUser.id);
      formData.append("accidentId", dataAnken.data.id);
      formData.append("content", data.content);
      fileList.forEach((file, index) => {
        formData.append("files", fileList[index].originFileObj, fileList[index].name);
      });
      createComment(formData);
    }
  };

  const exportPDF = async () => {
    const NAME_FILE = {
      1: "自動車事故受付票",
      2: "賠償事故受付票",
      3: "傷害・疾病受付票",
      4: "建物または設備・什器　事故受付票",
      5: "その他　事故受付票",
    };
    try {
      setLoadingExportPDF(true);
      const baseURL = process.env.REACT_APP_BASE_API_URL;
      const token = getCookie(STORAGEKEY.ACCESS_TOKEN);

      const response = await fetch(`${baseURL}/api/anken/generate-pdf/${paramsRouter?.id}`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = `${NAME_FILE[dataAnken?.data?.occurrenceType]}.pdf`;
        link.click();
        URL.revokeObjectURL(url);
      } else {
        console.error("Error generating PDF");
      }
      setLoadingExportPDF(false);
    } catch (error) {
      setLoadingExportPDF(false);
      console.error("Error:", error);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  {t("detailAnken.title")}
                </MDTypography>
                <LoadingButton
                  onClick={exportPDF}
                  endIcon={<FileDownloadIcon />}
                  loading={loadingExportPDF}
                  disabled={loadingExportPDF}
                  loadingPosition="end"
                  variant="outlined"
                  // color="white"
                  style={{ color: "#fff", border: "1px #fff solid", padding: "0px 14px" }}
                >
                  {t("detailAnken.btn.export")}
                </LoadingButton>
              </MDBox>
              <MDBox display="flex" py={1} pt={3} px={3}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize="20px"
                  sx={{ textDecoration: "underline" }}
                >
                  {t("detailAnken.nameSigning")}:
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    fontSize="20px"
                    sx={{ textDecoration: "underline" }}
                  >
                    &nbsp;{dataAnken?.data?.nameSigning}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} px={3}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailAnken.occurrenceTime")}:
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;
                    {dataAnken?.data?.occurrenceTime
                      ? moment(dataAnken?.data?.occurrenceTime)
                          .utcOffset("+09:00")
                          .format("YYYY/MM/DD HH:mm")
                      : ""}
                  </MDTypography>
                </MDTypography>
              </MDBox>

              <MDBox display="flex" py={1} px={3}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailAnken.nameContractSigning")}:
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{dataAnken?.data?.nameContractSigning}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} px={3}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailAnken.constractPhoneNumber")}:
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{dataAnken?.data?.constractPhoneNumber}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox
                display="flex"
                py={1}
                pb={3}
                px={3}
                style={{ display: "inline-block", maxWidth: "100%" }}
              >
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailAnken.constractEmail")}:{" "}
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    style={{ wordWrap: "break-word", display: "inline-block", maxWidth: "100%" }}
                  >
                    {dataAnken?.data?.constractEmail}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("detailAnken.title.1")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3} py={2} px={3}>
                {renderComment}
              </MDBox>
              {dataComment?.paginationInfo?.totalPages > 1 && (
                <MDBox
                  display="flex"
                  flexDirection={{ xs: "column", sm: "row" }}
                  justifyContent="flex-end"
                  alignItems={{ xs: "flex-start", sm: "center" }}
                  p={3}
                >
                  <Stack spacing={2}>
                    <Pagination
                      shape="rounded"
                      page={Number(dataComment?.paginationInfo?.currentPage)}
                      count={Number(dataComment?.paginationInfo?.totalPages)}
                      color="info"
                      onChange={(e, value) => setParams({ ...params, page: value })}
                    />
                  </Stack>
                </MDBox>
              )}
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("detailAnken.title.2")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3} py={2} px={3}>
                <InputController
                  name="content"
                  control={control}
                  type="text"
                  label={t("detailAnken.lable.content")}
                  multiline
                  rows="4"
                  disabled={isLoading || dataUser?.role === ROLE_USER.SUB_ADMIN}
                />
                <UploadFileController
                  name="co_files"
                  label={t("detailAnken.btn.upload")}
                  fileList={fileList}
                  setFileList={setFileList}
                  disabled={isLoading || dataUser?.role === ROLE_USER.SUB_ADMIN}
                />
                <MDBox mt={2} mb={1} align="center">
                  <MDButton
                    variant="gradient"
                    color="info"
                    onClick={handleSubmit(onSubmit)}
                    disabled={isLoading || dataUser?.role === ROLE_USER.SUB_ADMIN}
                  >
                    {t("detailAnken.btn.send")}
                  </MDButton>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content="削除しました。"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content="失敗ました。"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default DetailAnken;
