import { useNavigate, Link, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import masterCardLogo from "assets/images/logos/logo.jpg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { post } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { GoogleLoginButton, TwitterLoginButton } from "react-social-login-buttons";
import InputController from "components/withController/InputController";
import { checkTopLevelDomailEmail, regexPassword, regexNameFullWidth } from "utils/regex";

function Register() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useLocation();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [isHideButtonSns, setIsHideButtonSns] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .nullable(t("validate.E001"))
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    password: Yup.string()
      .nullable(t("validate.E001"))
      .required(t("validate.E001"))
      .matches(regexPassword, t("validate.E027")),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validate.password.match"))
      .required(t("validate.E001")),
    fullName: Yup.string()
      .required(t("validate.E001"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      email: null,
      password: null,
      confirmPassword: "",
      fullName: "",
      googleId: null,
      twitterId: null,
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const postRegisterUser = async (data) => post("api/register", data);

  const { mutate: registerUser, isLoading } = useMutation(postRegisterUser, {
    onSuccess: () => {
      openSuccessSB();
      setTimeout(() => {
        navigate("/login");
      }, 1000);
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.register");
    return () => {
      document.title = t("title");
    };
  }, []);

  useEffect(() => {
    if (params.state) {
      setValue("email", params.state?.email);
      setValue("fullName", params.state?.fullName);
      setValue("googleId", params.state?.googleId);
      setValue("twitterId", params.state?.twitterId);
    }
    setIsHideButtonSns(params.state?.twitterId || params.state?.googleId);
    navigate(params.pathname, { replace: true });
  }, []);

  const onRegister = (data) => {
    const newData = { ...data, fullName: data.fullName.trim(), email: data.email.trim() };
    registerUser(newData);
  };

  const handleGoogleLogin = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_BASE_API_URL}/api/auth/google`;
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleTwitterLogin = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_BASE_API_URL}/api/auth/twitter`;
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox
          variant="gradient"
          // bgColor="info"
          borderRadius="lg"
          // coloredShadow="info"
          mx={2}
          mt={-4}
          // p={2}
          // mb={1}
          textAlign="center"
        >
          <MDBox
            component="img"
            src={masterCardLogo}
            alt="master card"
            width="100%"
            borderRadius="lg"
            coloredShadow="info"
          />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form">
            <InputController
              name="email"
              control={control}
              type="email"
              label={t("lable.email")}
              textRequired="*"
            />
            <InputController
              name="password"
              control={control}
              type="password"
              label={t("lable.password")}
              textRequired="*"
            />
            <InputController
              name="confirmPassword"
              control={control}
              type="password"
              label={t("lable.password.confirm")}
              textRequired="*"
            />
            <InputController
              name="fullName"
              control={control}
              type="text"
              label={t("lable.name")}
              textRequired="*"
            />
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit(onRegister)}
                disabled={isLoading}
              >
                {t("register")}
              </MDButton>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography
                variant="button"
                color="info"
                fontWeight="medium"
                onClick={() => navigate("/login")}
                sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
              >
                {t("login")}
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1}>
              {!isHideButtonSns && (
                <GoogleLoginButton
                  style={{
                    borderRadius: "8px",
                    fontFamily: "Roboto",
                    margin: "px 0px",
                    width: "100%",
                    height: "40px",
                    fontSize: "12px",
                    border: "1px solid rgba(0,0,0,0.05)",
                    letterSpacing: "0.5px",
                  }}
                  text={t("register.google")}
                  onClick={handleGoogleLogin}
                />
              )}
              {!isHideButtonSns && (
                <TwitterLoginButton
                  style={{
                    borderRadius: "8px",
                    fontFamily: "Roboto",
                    margin: "8px 0px",
                    width: "100%",
                    height: "40px",
                    fontSize: "12px",
                    letterSpacing: "0.5px",
                  }}
                  text={t("register.twitter")}
                  onClick={handleTwitterLogin}
                />
              )}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("register.success")}
        // dateTime="11 mins ago"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("register.failed")}
        // dateTime="11 mins ago"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </BasicLayout>
  );
}

export default Register;
