import { useNavigate, Link, useLocation, useParams } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useMutation, useQuery } from "@tanstack/react-query";
import { put, get } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Footer from "examples/Footer";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { Grid } from "@mui/material";
import { ROLE_USER } from "constants/user";
import useUser from "hooks/useUser";
import InputController from "components/withController/InputController";
import { checkTopLevelDomailEmail, regexPassword, regexNameFullWidth } from "utils/regex";

function DetailUser() {
  const { t } = useTranslation();
  const { data } = useUser();
  const navigate = useNavigate();
  const params = useParams();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string()
      .required(t("validate.required"))
      .trim(t("validate.E001"))
      .matches(regexNameFullWidth, t("validate.E003"))
      .max(50, t("validate.E003")),
    email: Yup.string()
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    password: Yup.string().test("Function check password", t("validate.E027"), (value) => {
      if (!value) {
        return true;
      }
      return regexPassword.test(value);
    }),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("password"), null], t("validate.password.match"))
      .when("password", {
        is: (value) => value,
        then: Yup.string().required(t("validate.E001")),
      }),
  });

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      fullName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const getDetailUser = async () => get(`api/users/${params.id}`);
  const putUser = async (data) => put(`api/users/${params.id}`, data);

  const { data: dataUser } = useQuery(["getDetailUser"], getDetailUser, {
    keepPreviousData: true,
  });

  const { mutate: updateUser, isLoading } = useMutation(putUser, {
    onSuccess: () => {
      openSuccessSB();
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.detailUser");
    return () => {
      document.title = t("title");
    };
  }, []);

  useEffect(() => {
    if (dataUser) {
      setValue("fullName", dataUser.data.fullName);
      setValue("email", dataUser.data.email);
    }
  }, [dataUser]);

  const onUpdateUser = (data) => {
    const newData = {
      fullName: data.fullName.trim(),
      email: data.email.trim(),
      password: data.password || null,
    };
    updateUser(newData);
  };
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="center">
          <Grid item xs={12} sm={9} md={6} lg={6} xl={6}>
            <Card>
              <MDBox pt={4} pb={3} px={3}>
                <MDBox component="form">
                  <InputController
                    name="fullName"
                    control={control}
                    type="text"
                    label={t("lable.name")}
                    textRequired="*"
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                  <InputController
                    name="email"
                    control={control}
                    type="email"
                    label={t("lable.email")}
                    disabled
                    textRequired="*"
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                  <InputController
                    name="password"
                    control={control}
                    type="password"
                    label={t("lable.password")}
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                  <InputController
                    name="confirmPassword"
                    control={control}
                    type="password"
                    label={t("lable.password.confirm")}
                    disabled={data?.role === ROLE_USER.SUB_ADMIN || isLoading}
                    tabIndexDisable={data?.role === ROLE_USER.SUB_ADMIN}
                  />
                  <MDBox mt={4} mb={1}>
                    <MDButton
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      onClick={handleSubmit(onUpdateUser)}
                      disabled={isLoading || data?.role === ROLE_USER.SUB_ADMIN}
                    >
                      {t("detailUser.btn.update")}
                    </MDButton>
                  </MDBox>
                  <MDBox mt={2} mb={1}>
                    <MDButton
                      variant="outlined"
                      color="info"
                      fullWidth
                      onClick={() => navigate(-1)}
                    >
                      {t("detailUser.btn.back")}
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("detailUser.update.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("detailUser.update.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default DetailUser;
