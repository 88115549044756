import { Cookies } from "react-cookie";

const cookies = new Cookies();

export const STORAGEKEY = {
  ACCESS_TOKEN: "access_token_hsp",
  REFRESH_TOKEN: "refresh_token_hsp",
};

export const setCookie = (key, value) => {
  cookies.set(key, value, { path: "/" });
};

export const getCookie = (key) => cookies.get(key);

export const removeCookie = (key) => cookies.remove(key, { path: "/" });
