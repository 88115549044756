import { useNavigate, Link, useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import masterCardLogo from "assets/images/logos/logo.jpg";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { useMutation } from "@tanstack/react-query";
import { post, setAuthHeader } from "api/BaseRequest";
import MDSnackbar from "components/MDSnackbar";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoogleLoginButton, TwitterLoginButton } from "react-social-login-buttons";
import { STORAGEKEY, setCookie } from "utils/storage";
import InputController from "components/withController/InputController";
import { checkTopLevelDomailEmail, regexPassword, regexNameFullWidth } from "utils/regex";

function LoginUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useLocation();

  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .nullable(t("validate.E001"))
      .required(t("validate.E001"))
      .matches(/^[ -~]+$/, t("validate.E007"))
      .email(t("validate.E006"))
      .test("", t("validate.E006"), checkTopLevelDomailEmail)
      .max(50, t("validate.E007")),
    password: Yup.string()
      .nullable(t("validate.E001"))
      .required(t("validate.E001"))
      .matches(regexPassword, t("validate.E027")),
    // .matches(/^[a-zA-Z0-9]+$/, t("validate.E027"))
    // .min(8, t("validate.E027"))
    // .max(16, t("validate.E027")),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      email: null,
      password: null,
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const postLoginUser = async (data) => post("api/login", data);

  const { mutate: loginUser, isLoading } = useMutation(postLoginUser, {
    onSuccess: (data) => {
      openSuccessSB();
      setCookie(STORAGEKEY.ACCESS_TOKEN, data?.data?.accessToken);
      setCookie(STORAGEKEY.REFRESH_TOKEN, data?.data?.refreshToken);
      setAuthHeader(data?.data?.accessToken);
      if (params.state?.redirect) {
        navigate(params.state?.redirect);
      } else {
        navigate("/dashboard");
      }
    },
    onError: () => {
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.login");
    return () => {
      document.title = t("title");
    };
  }, []);

  const handleGoogleLogin = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_BASE_API_URL}/api/auth/google`;
    } catch (err) {
      console.log(err.message);
    }
  };

  const handleTwitterLogin = async () => {
    try {
      window.location.href = `${process.env.REACT_APP_BASE_API_URL}/api/auth/twitter`;
    } catch (err) {
      console.log(err.message);
    }
  };
  const onLogin = (data) => {
    loginUser(data);
  };

  return (
    <BasicLayout>
      <Card>
        <MDBox variant="gradient" borderRadius="lg" mx={2} mt={-4} textAlign="center">
          <MDBox
            component="img"
            src={masterCardLogo}
            alt="master card"
            width="100%"
            borderRadius="lg"
            coloredShadow="info"
          />
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form">
            <InputController
              name="email"
              control={control}
              type="email"
              label={t("lable.email")}
              textRequired="*"
            />
            <InputController
              name="password"
              control={control}
              type="password"
              label={t("lable.password")}
              textRequired="*"
            />
            <MDBox mt={4} mb={1}>
              <MDButton
                type="submit"
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit(onLogin)}
                disabled={isLoading}
              >
                {t("login")}
              </MDButton>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography variant="button" color="text">
                {t("loginUser.desc.register")}{" "}
                <MDTypography
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  onClick={() => navigate("/register")}
                  sx={{
                    "&:hover": { cursor: "pointer", textDecoration: "underline" },
                    display: "inline-block",
                  }}
                >
                  {t("loginUser.link.register")}
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDBox textAlign="center">
              <MDTypography
                variant="button"
                color="info"
                fontWeight="medium"
                onClick={() => navigate("/forgot-password")}
                sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
              >
                {t("loginAdmin.forgotPassword")}
              </MDTypography>
            </MDBox>
            <MDBox mt={1} mb={1}>
              <GoogleLoginButton
                style={{
                  borderRadius: "8px",
                  fontFamily: "Roboto",
                  margin: "px 0px",
                  width: "100%",
                  height: "40px",
                  fontSize: "12px",
                  border: "1px solid rgba(0,0,0,0.05)",
                  letterSpacing: "0.5px",
                }}
                text={t("loginUser.google")}
                onClick={handleGoogleLogin}
              />
              <TwitterLoginButton
                style={{
                  borderRadius: "8px",
                  fontFamily: "Roboto",
                  margin: "8px 0px",
                  width: "100%",
                  height: "40px",
                  fontSize: "12px",
                  letterSpacing: "0.5px",
                }}
                text={t("loginUser.twitter")}
                onClick={handleTwitterLogin}
              />
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("login.success")}
        // dateTime="11 mins ago"
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("loginUser.failed")}
        // dateTime="11 mins ago"
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </BasicLayout>
  );
}

export default LoginUser;
