import { Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";

function InputController({
  name,
  control,
  type = "text",
  label,
  placeholder = "",
  isAutoFormatPhoneNumber = false,
  multiline = false,
  rows,
  tabIndexDisable,
  disabled = false,
  textRequired = "",
  grid = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
}) {
  return (
    <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
          <MDBox mb={2}>
            <MDInput
              InputLabelProps={{
                style: {
                  paddingTop: "2px",
                },
              }}
              inputProps={{
                style: {
                  paddingRight: error?.message ? "30px" : "15px",
                },
                tabIndex: tabIndexDisable ? "-1" : "",
              }}
              type={type}
              disabled={disabled}
              multiline={multiline}
              rows={rows}
              // label={label}
              label={
                <span>
                  {label}
                  {textRequired && <span style={{ color: "red" }}>{textRequired}</span>}
                </span>
              }
              placeholder={placeholder}
              fullWidth
              error={error}
              onChange={(e) => {
                if (isAutoFormatPhoneNumber) {
                  // const input = e.target.value.replace(/\D/g, "").substring(0, 11);
                  // const first = input.substring(0, 3);
                  // const middle = input.substring(3, 7);
                  // const last = input.substring(7, 11);
                  // if (input.length > 7) {
                  //   onChange(`${first}-${middle}-${last}`);
                  // } else if (input.length > 3) {
                  //   onChange(`${first}-${middle}`);
                  // } else if (input.length >= 0) {
                  //   onChange(input);
                  // }
                  const input = e.target.value.replace(/\D/g, "");
                  onChange(input);
                } else {
                  onChange(e.target.value);
                }
              }}
              onBlur={onBlur}
              value={value}
            />
            {error?.message && (
              <MDTypography variant="caption" color="error">
                {error?.message}
              </MDTypography>
            )}
          </MDBox>
        )}
      />
    </Grid>
  );
}
export default InputController;
