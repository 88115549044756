import { Card, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DatePickerController from "components/withController/DatePickerController";
import InputController from "components/withController/InputController";
import TimePickerController from "components/withController/TimePickerController";

import SelectTableController from "components/withController/SelectTableController";
import SelectController from "components/withController/SelectController";
import DateTimePickerController from "components/withController/DateTimePickerController";
import { useTranslation } from "react-i18next";

function FormAccident({
  control,
  setValue,
  clearErrors,
  isAccidentInfomation = false,
  isCreateAnken = false,
  isDetailAccident = false,
  isSubAdmin = false,
  title = "",
  disabled,
}) {
  const { t } = useTranslation();

  return (
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          {title}
        </MDTypography>
      </MDBox>
      <MDBox pt={4} pb={3} px={3}>
        <MDBox>
          <Grid container columnSpacing={3}>
            <InputController
              name="reporterName"
              control={control}
              type="text"
              label={t("lable.reporterName")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="reporterPhoneNumber"
              control={control}
              type="text"
              label={t("lable.reporterPhoneNumber")}
              isAutoFormatPhoneNumber
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <InputController
              name="reporterEmail"
              control={control}
              type="email"
              label={t("lable.reporterEmail")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <DateTimePickerController
              name="occurrenceTime"
              control={control}
              label={t("lable.occurrenceTime")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            <SelectController
              name="occurrenceType"
              control={control}
              label={t("lable.occurrenceType")}
              option={[
                { id: 1, name: t("lable.occurrenceType.1") },
                { id: 2, name: t("lable.occurrenceType.2") },
                { id: 3, name: t("lable.occurrenceType.3") },
                { id: 4, name: t("lable.occurrenceType.4") },
                { id: 5, name: t("lable.occurrenceType.5") },
              ]}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            {(isDetailAccident || isCreateAnken) && (
              <SelectController
                name="reciprocalStaff"
                control={control}
                label={t("lable.reciprocalStaff")}
                option={[
                  { id: 1, name: t("lable.reciprocalStaff.1") },
                  { id: 2, name: t("lable.reciprocalStaff.2") },
                  { id: 3, name: t("lable.reciprocalStaff.3") },
                  { id: 4, name: t("lable.reciprocalStaff.4") },
                  { id: 5, name: t("lable.reciprocalStaff.5") },
                  { id: 6, name: t("lable.reciprocalStaff.6") },
                  { id: 7, name: t("lable.reciprocalStaff.7") },
                  { id: 8, name: t("lable.reciprocalStaff.8") },
                  { id: 9, name: t("lable.reciprocalStaff.9") },
                  { id: 10, name: t("lable.reciprocalStaff.10") },
                  { id: 11, name: t("lable.reciprocalStaff.11") },
                  { id: 12, name: t("lable.reciprocalStaff.12") },
                  { id: 13, name: t("lable.reciprocalStaff.13") },
                  { id: 14, name: t("lable.reciprocalStaff.14") },
                  { id: 15, name: t("lable.reciprocalStaff.15") },
                  { id: 16, name: t("lable.reciprocalStaff.16") },
                  { id: 17, name: t("lable.reciprocalStaff.17") },
                  { id: 18, name: t("lable.reciprocalStaff.18") },
                ]}
                // textRequired={t("text.required")}
                disabled={isSubAdmin || disabled}
                grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
              />
            )}
            {isCreateAnken && (
              <>
                <SelectController
                  name="insuranceType"
                  control={control}
                  label={t("lable.insuranceType")}
                  option={[
                    { id: 1, name: t("lable.insuranceType.1") },
                    { id: 2, name: t("lable.insuranceType.2") },
                    { id: 3, name: t("lable.insuranceType.3") },
                    { id: 4, name: t("lable.insuranceType.4") },
                    { id: 5, name: t("lable.insuranceType.5") },
                    { id: 6, name: t("lable.insuranceType.6") },
                    { id: 7, name: t("lable.insuranceType.7") },
                    { id: 8, name: t("lable.insuranceType.8") },
                    { id: 9, name: t("lable.insuranceType.9") },
                    { id: 10, name: t("lable.insuranceType.10") },
                  ]}
                  textRequired={t("text.required")}
                  disabled={isSubAdmin || disabled}
                  grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
                />
                <SelectController
                  name="status"
                  control={control}
                  label={t("lable.status.accident")}
                  option={[
                    { id: 1, name: t("lable.status.accident.1") },
                    { id: 2, name: t("lable.status.accident.2") },
                    { id: 3, name: t("lable.status.accident.3") },
                    { id: 4, name: t("lable.status.accident.4") },
                    { id: 5, name: t("lable.status.accident.5") },
                    { id: 6, name: t("lable.status.accident.6") },
                  ]}
                  textRequired={t("text.required")}
                  disabled={isSubAdmin || disabled}
                  grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
                />
              </>
            )}
            <InputController
              name="nameSigning"
              control={control}
              type="text"
              label={t("lable.nameSigning")}
              textRequired={t("text.required")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            {isAccidentInfomation ? (
              <InputController
                name="nameContractSigning"
                control={control}
                type="text"
                label={t("lable.nameContractSigning.user")}
                disabled={isSubAdmin || disabled}
                grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
              />
            ) : (
              <InputController
                name="nameContractSigning"
                control={control}
                type="text"
                label={t("lable.nameContractSigning")}
                textRequired={t("text.required")}
                disabled={isSubAdmin || disabled}
                grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
              />
            )}
            <InputController
              name="constractPhoneNumber"
              control={control}
              type="text"
              label={t("lable.constractPhoneNumber")}
              isAutoFormatPhoneNumber
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
            {isAccidentInfomation ? (
              <InputController
                name="constractEmail"
                control={control}
                type="email"
                label={t("lable.constractEmail.user")}
                // textRequired={t("text.required")}
                disabled={isSubAdmin || disabled}
                grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
              />
            ) : (
              <SelectTableController
                name="constractEmail"
                control={control}
                setValue={setValue}
                clearErrors={clearErrors}
                label={t("lable.constractEmail")}
                textRequired={t("text.required")}
                disabled={isSubAdmin || disabled}
                grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
              />
            )}
            <InputController
              name="address"
              control={control}
              type="text"
              label={t("lable.address")}
              disabled={isSubAdmin || disabled}
              grid={{ xs: 12, sm: 12, md: 6, lg: 6, xl: 6 }}
            />
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}
export default FormAccident;
