import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";
import useUser from "hooks/useUser";
import { ROLE_USER } from "constants/user";
import { useNavigate } from "react-router-dom";
import { get } from "api/BaseRequest";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function Dashboard() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data } = useUser();
  const getTop = async () => get(`api/top`);
  const { data: dataTop } = useQuery(["getTop"], getTop, {
    keepPreviousData: true,
  });

  useEffect(() => {
    document.title = t("common.title.top");
    return () => {
      document.title = t("common.title.top");
    };
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          {(data?.role === ROLE_USER.ADMIN || data?.role === ROLE_USER.SUB_ADMIN) && (
            <>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="people_alt_icon"
                    title={t("dashboard.totalUser")}
                    count={dataTop?.data?.totalUser || 0}
                    percentage={{
                      color: "dark",
                      label: t("dashboard.admin.user"),
                      typeBtn: true,
                      onClick: () => navigate("/users"),
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="table_view"
                    title={t("dashboard.totalAccident")}
                    count={dataTop?.data?.totalAccident || 0}
                    percentage={{
                      color: "info",
                      label: t("dashboard.admin.accident"),
                      typeBtn: true,
                      onClick: () => navigate("/accident"),
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="success"
                    icon="ballot_icon"
                    title={t("dashboard.totalAnken")}
                    count={dataTop?.data?.totalAnken || 0}
                    percentage={{
                      color: "success",
                      amount: "",
                      label: t("dashboard.admin.anken"),
                      typeBtn: true,
                      onClick: () => navigate("/anken"),
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={3}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="primary"
                    icon="person_add"
                    title="&nbsp;"
                    count="&nbsp;"
                    percentage={{
                      color: "error",
                      amount: "",
                      label: t("dashboard.admin.other"),
                      typeBtn: true,
                      onClick: () => {},
                    }}
                  />
                </MDBox>
              </Grid>
            </>
          )}
          {data?.role === ROLE_USER.USER && (
            <>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    color="dark"
                    icon="home_icon"
                    title="&nbsp;"
                    count="&nbsp;"
                    percentage={{
                      color: "dark",
                      label: t("dashboard.user.home"),
                      typeBtn: true,
                      onClick: () => navigate("/"),
                    }}
                  />
                </MDBox>
              </Grid>
              <Grid item xs={12} md={6} lg={6}>
                <MDBox mb={1.5}>
                  <ComplexStatisticsCard
                    icon="ballot_icon"
                    title={t("dashboard.totalAnken")}
                    count={dataTop?.data?.totalAnken || 0}
                    percentage={{
                      color: "info",
                      label: t("dashboard.user.anken"),
                      typeBtn: true,
                      onClick: () => navigate("/anken"),
                    }}
                  />
                </MDBox>
              </Grid>
            </>
          )}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
