import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useMemo, useState, useEffect } from "react";
import { get, del } from "api/BaseRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import MDButton from "components/MDButton";
import { Dialog, DialogActions, DialogContent } from "@mui/material";
import { useTranslation } from "react-i18next";
import MDSnackbar from "components/MDSnackbar";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { TEXT_OCCURRENCE_TYPE, TEXT_INSURANCE_TYPE, TEXT_STATUS } from "constants/accident";
import useUser from "hooks/useUser";

const TextRow = ({ text }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1} style={{ minWidth: "72px" }}>
    <MDBox lineHeight={1}>
      <MDTypography
        variant="caption"
        fontWeight="medium"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </MDTypography>
    </MDBox>
  </MDBox>
);

function DetailUserForAnken() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const paramsRouter = useParams();
  const { data: dataUser } = useUser();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [rowUser, setRowUser] = useState({});
  const [valueSearch, setValueSearch] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const getListAnkenForUser = async () => get(`api/anken/user/${paramsRouter?.id}`, params);
  const deleteAnkenAPI = async (id) => del(`api/anken/${id}`);

  const { data, refetch, isFetching } = useQuery(
    ["getListAnkenForUser", { ...params }],
    getListAnkenForUser,
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  const { mutate: deleteAnken, isLoading } = useMutation(deleteAnkenAPI, {
    onSuccess: (data) => {
      refetch();
      setOpenDialog(false);
      openSuccessSB();
    },
    onError: () => {
      setOpenDialog(false);
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.detailUserForAnken");
    return () => {
      document.title = t("title");
    };
  }, []);

  const columns = [
    { Header: "No", accessor: "no", width: "20px", align: "left" },
    {
      Header: t("detailUserForAnken.occurrenceTime"),
      accessor: "occurrenceTime",
      align: "left",
    },
    {
      Header: t("detailUserForAnken.occurrenceType"),
      accessor: "occurrenceType",
      align: "left",
    },
    {
      Header: t("detailUserForAnken.insuranceType"),
      accessor: "insuranceType",
      align: "left",
    },
    {
      Header: t("detailUserForAnken.reporterName"),
      accessor: "reporterName",
      align: "left",
    },
    // {
    //   Header: t("detailUserForAnken.nameSigning"),
    //   accessor: "nameSigning",
    //
    //   align: "left",
    // },
    { Header: t("detailUserForAnken.status"), accessor: "status", align: "left" },
    { Header: "", accessor: "action", align: "center" },
  ];

  const rows = useMemo(() => {
    if (data?.data?.length) {
      return data.data?.map((item, index) => ({
        no: (
          <Link to={`/anken/detail/${item.id}`}>
            <MDTypography
              variant="caption"
              color="text"
              fontWeight="medium"
              sx={{ "&:hover": { cursor: "pointer", textDecoration: "underline" } }}
            >
              {Number(data?.paginationInfo?.currentPage) * Number(data?.paginationInfo?.perPage) -
                (Number(data?.paginationInfo?.perPage) - index) +
                1}
            </MDTypography>
          </Link>
        ),
        occurrenceTime: (
          <TextRow
            text={
              item?.occurrenceTime
                ? moment(item?.occurrenceTime).utcOffset("+09:00").format("YYYY/MM/DD HH:mm")
                : ""
            }
          />
        ),
        occurrenceType: <TextRow text={TEXT_OCCURRENCE_TYPE[item?.occurrenceType]} />,
        insuranceType: <TextRow text={TEXT_INSURANCE_TYPE[item?.insuranceType]} />,
        reporterName: <TextRow text={item?.reporterName} />,
        // nameSigning: <TextRow text={item?.nameSigning} />,
        status: <TextRow text={TEXT_STATUS[item?.status]} />,
        action: (
          <MDBox sx={{ display: "flex", gap: "8px" }}>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={() => navigate(`/anken/detail/${item.id}`)}
            >
              {t("detailUserForAnken.btn.detail")}
            </MDButton>
            {/* <MDButton
              variant="outlined"
              color="info"
              size="small"
              disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
              onClick={() => navigate(`/anken/edit/${item.id}`)}
            >
              {t("detailUserForAnken.btn.edit")}
            </MDButton> */}
            {/* <MDButton
              variant="outlined"
              color="error"
              size="small"
              disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
              onClick={() => {
                setRowUser(item);
                setOpenDialog(true);
              }}
            >
              {t("detailUserForAnken.btn.delete")}
            </MDButton> */}
          </MDBox>
        ),
      }));
    }
    return [];
  }, [data]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={3} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={2}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  {t("detailUserForAnken.title")}
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pt={3} px={3}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  fontSize="20px"
                  textDecoration="underline"
                  sx={{ textDecoration: "underline" }}
                >
                  {t("detailUserForAnken.nameSigning")}:
                  <MDTypography
                    variant="button"
                    fontWeight="bold"
                    fontSize="20px"
                    sx={{ textDecoration: "underline" }}
                  >
                    &nbsp;{data?.data[0]?.nameSigning} 様
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} px={3}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailUserForAnken.nameContractSigning")}:
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{data?.data[0]?.nameContractSigning}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} px={3}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailUserForAnken.constractPhoneNumber")}:
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{data?.data[0]?.constractPhoneNumber}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} px={3}>
                <MDTypography
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  style={{ display: "inline-block", maxWidth: "100%" }}
                >
                  {t("detailUserForAnken.constractEmail")}:
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    style={{ wordWrap: "break-word", display: "inline-block", maxWidth: "100%" }}
                  >
                    &nbsp;{data?.data[0]?.constractEmail}
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDBox display="flex" py={1} pb={3} px={3}>
                <MDTypography variant="button" fontWeight="bold" textTransform="capitalize">
                  {t("detailUserForAnken.address")}:
                  <MDTypography variant="button" fontWeight="regular" color="text">
                    &nbsp;{data?.data[0]?.address}
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("detailUserForAnken.title.1")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries
                  noEndBorder
                  paginationInfo={data?.paginationInfo}
                  canSearch
                  params={params}
                  setParams={setParams}
                  isLoading={isFetching}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent sx={{ paddingTop: "20px" }}>
          <MDTypography variant="subtitle2" align="center">
            {t("detailUserForAnken.title.1")}
          </MDTypography>
        </DialogContent>
        <DialogActions>
          <MDButton
            variant="outlined"
            color="info"
            size="small"
            onClick={() => setOpenDialog(false)}
          >
            {t("detailUserForAnken.btn.no")}
          </MDButton>
          <MDButton
            variant="contained"
            color="info"
            size="small"
            onClick={() => deleteAnken(rowUser?.id)}
          >
            {t("detailUserForAnken.btn.yes")}
          </MDButton>
        </DialogActions>
      </Dialog>
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("detailUserForAnken.delete.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("detailUserForAnken.delete.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default DetailUserForAnken;
