import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { useMemo, useState, useEffect } from "react";
import avatar from "assets/images/default-avatar.jpg";
import MDAvatar from "components/MDAvatar";
import { get, del } from "api/BaseRequest";
import { useMutation, useQuery } from "@tanstack/react-query";
import MDBadge from "components/MDBadge";
import MDButton from "components/MDButton";
import { useTranslation } from "react-i18next";
import MDSnackbar from "components/MDSnackbar";
import { useNavigate } from "react-router-dom";
import useUser from "hooks/useUser";
import { ROLE_USER } from "constants/user";
import InputSearchController from "components/withController/InputSearchController";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import DialogConfirm from "components/dialog/DialogConfirm";

const Author = ({ image, name }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1} style={{ minWidth: "280px" }}>
    <MDAvatar src={image} name={name} size="sm" />
    <MDBox ml={2} lineHeight={1}>
      <MDTypography
        display="block"
        variant="button"
        fontWeight="medium"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          fontWeight: "bold",
        }}
      >
        {name}
      </MDTypography>
    </MDBox>
  </MDBox>
);

const Email = ({ email }) => (
  <MDBox display="flex" alignItems="center" lineHeight={1}>
    <MDBox lineHeight={1}>
      <MDTypography
        variant="caption"
        fontWeight="medium"
        sx={{
          display: "-webkit-box",
          WebkitLineClamp: 3,
          WebkitBoxOrient: "vertical",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "400px",
        }}
      >
        {email}
      </MDTypography>
    </MDBox>
  </MDBox>
);

function ListUser() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: dataUser } = useUser();
  const [params, setParams] = useState({
    page: 1,
    limit: 10,
    search: "",
  });
  const [rowUser, setRowUser] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);

  const validationSchema = Yup.object().shape({
    search: Yup.string().max(255, t("validate.E015")),
  });

  const { control, handleSubmit } = useForm({
    defaultValues: {
      search: "",
    },
    mode: "onChange",
    resolver: yupResolver(validationSchema),
  });

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const getListUser = async () => get("api/users", params);
  const deleteUserAPI = async (id) => del(`api/users/${id}`);

  const { data, refetch, isFetching } = useQuery(["getListUser", { ...params }], getListUser, {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
  });
  const { mutate: deleteUser } = useMutation(deleteUserAPI, {
    onSuccess: (data) => {
      refetch();
      setOpenDialog(false);
      openSuccessSB();
    },
    onError: () => {
      setOpenDialog(false);
      openErrorSB();
    },
  });

  useEffect(() => {
    document.title = t("title.listUser");
    return () => {
      document.title = t("title");
    };
  }, []);

  const columns = [
    { Header: "No", accessor: "no", width: "24px", align: "left" },
    { Header: t("listUser.fullName"), accessor: "author", align: "left" },
    { Header: t("listUser.email"), accessor: "email", align: "left" },
    { Header: t("listUser.status"), accessor: "status", align: "center" },
    { Header: "", accessor: "action", align: "center" },
  ];

  const rows = useMemo(() => {
    if (data?.data?.length) {
      return data.data?.map((item, index) => ({
        no: (
          <MDTypography variant="caption" fontWeight="medium">
            {Number(data?.paginationInfo?.currentPage) * Number(data?.paginationInfo?.perPage) -
              (Number(data?.paginationInfo?.perPage) - index) +
              1}
          </MDTypography>
        ),
        author: <Author image={avatar} name={item?.fullName} />,
        email: <Email email={item?.email} />,
        status: (
          <MDBox ml={-1} style={{ minWidth: "80px" }}>
            <MDBadge
              badgeContent={
                item?.status ? t("listUser.status.active") : t("listUser.status.inactive")
              }
              color={item?.status ? "success" : "error"}
              variant="gradient"
              size="sm"
            />
          </MDBox>
        ),
        action: (
          <MDBox sx={{ display: "flex", gap: "8px" }}>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={() => navigate(`/users/detail/${item.id}`)}
            >
              {t("listUser.btn.detail")}
            </MDButton>
            <MDButton
              variant="outlined"
              color="error"
              size="small"
              disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
              onClick={() => {
                setRowUser(item);
                setOpenDialog(true);
              }}
            >
              {t("listUser.btn.delete")}
            </MDButton>
          </MDBox>
        ),
      }));
    }
    return [];
  }, [data]);

  const onSearch = (data) => {
    setParams({
      ...params,
      page: 1,
      search: data.search,
    });
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox component="form">
        <Grid container spacing={1}>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <InputSearchController
              name="search"
              control={control}
              placeholder={t("listUser.placeholder.search")}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <MDButton
              type="submit"
              variant="outlined"
              color="info"
              fullWidth
              style={{ height: "43px" }}
              onClick={handleSubmit(onSearch)}
            >
              {t("listUser.btn.search")}
            </MDButton>
          </Grid>
          <Grid item xs={0} sm={0} md={3} lg={6} xl={6} />
          <Grid item xs={12} sm={12} md={3} lg={2} xl={2}>
            <MDButton
              variant="contained"
              color="info"
              fullWidth
              style={{ height: "43px" }}
              disabled={dataUser?.role === ROLE_USER.SUB_ADMIN}
              onClick={() => navigate("/users/create")}
            >
              {t("listUser.btn.register")}
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  {t("listUser.title")}
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={false}
                  entriesPerPage={false}
                  showTotalEntries
                  noEndBorder
                  paginationInfo={data?.paginationInfo}
                  canSearch
                  params={params}
                  setParams={setParams}
                  isLoading={isFetching}
                />
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
      <DialogConfirm
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        renderDialogContent={() => (
          <MDTypography variant="subtitle2" align="center">
            {/* {`${rowUser?.fullName} - ${rowUser?.email} を削除したいですか?`} */}
            {t("text.delete")}
          </MDTypography>
        )}
        renderDialogActions={() => (
          <>
            <MDButton
              variant="outlined"
              color="info"
              size="small"
              onClick={() => setOpenDialog(false)}
            >
              {t("listUser.btn.no")}
            </MDButton>
            <MDButton
              variant="contained"
              color="info"
              size="small"
              onClick={() => deleteUser(rowUser?.id)}
            >
              {t("listUser.btn.yes")}
            </MDButton>
          </>
        )}
      />
      <MDSnackbar
        color="success"
        icon="check"
        title={t("success")}
        content={t("detailUser.update.success")}
        open={successSB}
        onClose={closeSuccessSB}
        close={closeSuccessSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
      <MDSnackbar
        color="error"
        icon="warning"
        title={t("failed")}
        content={t("detailUser.update.failed")}
        open={errorSB}
        onClose={closeErrorSB}
        close={closeErrorSB}
        bgWhite
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
      />
    </DashboardLayout>
  );
}

export default ListUser;
