import { useEffect, useState } from "react";
import { FilePdfOutlined, PlaySquareOutlined, PlusOutlined } from "@ant-design/icons";
import { Modal, Upload, message } from "antd";
import FileSaver from "file-saver";
import { VIDEOS, PDF } from "constants/fileTypes";

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function UploadFileController({
  name,
  label,
  fileList,
  setFileList,
  disabled = false,
  limit = 10,
}) {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  let isViewMessErrType = false;
  let isViewMessErrSize = false;

  useEffect(() => {
    if (isViewMessErrType) {
      setTimeout(() => {
        isViewMessErrType = false;
      }, 1000);
    }
    if (isViewMessErrSize) {
      setTimeout(() => {
        isViewMessErrSize = false;
      }, 1000);
    }
  }, [isViewMessErrType, isViewMessErrSize]);

  useEffect(() => {
    if (!fileList?.length) {
      setTimeout(() => {
        setPreviewOpen(false);
      }, 500);
    }
  }, [fileList]);

  // useEffect(() => {
  //   // Prevent scroll when modal open ios
  //   if (previewOpen) {
  //     document.body.style.overflow = "hidden";
  //   } else {
  //     document.body.style.overflow = "unset";
  //   }
  //   return () => {
  //     document.body.style.overflow = "unset";
  //   };
  // }, [previewOpen]);

  const handleClose = () => {
    setPreviewOpen(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleClose);

    return () => window.removeEventListener("scroll", handleClose);
  }, []);

  const handleCancel = () => setPreviewOpen(false);

  const beforeUpload = (file) => {
    const validateFileType =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/mov" ||
      file.type === "video/quicktime" ||
      file.type === "video/mp4" ||
      file.type === "application/pdf";
    if (!validateFileType && !isViewMessErrType) {
      message.error(
        "フォーマットが正しくありません。PDF, MOV, MP4, PNG, JPG, JPEGで入力してください。"
      );
      isViewMessErrType = true;
    }
    const validateFileSize = file.size / 1024 / 1024 < 8;
    if (!validateFileSize && !isViewMessErrSize) {
      message.error("アップロードできません。8000KBまでアップロードしてください。");
      isViewMessErrSize = true;
    }
    return false;
  };

  const handlePreview = async (file) => {
    const extension = file?.url?.split(".").pop();
    if (
      VIDEOS.includes(extension?.toLocaleLowerCase()) ||
      PDF.includes(extension?.toLocaleLowerCase())
    ) {
      (() => {
        FileSaver.saveAs(file?.url, file?.name);
      })();
      return;
    }

    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf("/") + 1));
  };

  const handleChange = ({ fileList: newFileList }) => {
    const validateFileType = (file) =>
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "video/mov" ||
      file.type === "video/quicktime" ||
      file.type === "video/mp4" ||
      file.type === "application/pdf";

    const validateFileSize = (file) => file.size / 1024 / 1024 < 8;
    if (newFileList?.length > fileList?.length) {
      const newFileListValid = newFileList.filter((file) => {
        if (file?.urlFile) {
          return true;
        }
        return validateFileType(file) && validateFileSize(file);
      });

      if (newFileListValid.length > 10) {
        const sliceNewFileList = newFileListValid.slice(0, 10);
        setFileList(sliceNewFileList);
      } else {
        setFileList(newFileListValid);
      }
    } else {
      setFileList(newFileList);
    }
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          margin: 5,
        }}
      >
        {label}
      </div>
    </div>
  );

  const renderIcon = (file) => {
    const extension = file?.url?.split(".").pop();
    if (
      file.type === "video/mov" ||
      file.type === "video/quicktime" ||
      file.type === "video/mp4" ||
      VIDEOS.includes(extension?.toLocaleLowerCase())
    ) {
      return <PlaySquareOutlined />;
    }
    return <FilePdfOutlined />;
  };

  return (
    <>
      <Upload
        action=""
        accept="video/*,image/*,.pdf"
        listType="picture-card"
        fileList={fileList}
        onPreview={handlePreview}
        onChange={handleChange}
        beforeUpload={beforeUpload}
        disabled={disabled}
        multiple
        iconRender={renderIcon}
        style={{ display: "none" }}
      >
        {fileList?.length >= limit ? null : uploadButton}
      </Upload>
      <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </>
  );
}
export default UploadFileController;
