import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";

function InputSearchController({ name, control, type = "text", placeholder = "", label }) {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
        <MDBox mb={2}>
          <MDInput
            InputLabelProps={{
              style: {
                paddingTop: "2px",
                fontSize: "0.875rem",
              },
            }}
            InputProps={{
              style: {
                paddingRight: error?.message ? "20px" : "15px",
                height: "44px",
              },
            }}
            size="small"
            type={type}
            placeholder={placeholder}
            fullWidth
            error={error}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            label={placeholder}
          />
          {error?.message && (
            <MDTypography variant="caption" color="error">
              {error?.message}
            </MDTypography>
          )}
        </MDBox>
      )}
    />
  );
}
export default InputSearchController;
