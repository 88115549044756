import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import DefaultNavbarLink from "examples/Navbars/DefaultNavbar/DefaultNavbarLink";
import DefaultNavbarMobile from "examples/Navbars/DefaultNavbar/DefaultNavbarMobile";
import breakpoints from "assets/theme/base/breakpoints";
import { useMaterialUIController } from "context";
import masterCardLogo from "assets/images/logos/logo.jpg";
import { useNavigate } from "react-router-dom";
import { removeCookie, STORAGEKEY, getCookie } from "utils/storage";
import useUser from "hooks/useUser";
import { ROLE_USER } from "constants/user";

function DefaultNavbar({ transparent, light }) {
  const navigate = useNavigate();
  const auth = getCookie(STORAGEKEY.ACCESS_TOKEN);
  const { data: dataUser } = useUser();
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const [mobileNavbar, setMobileNavbar] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const openMobileNavbar = ({ currentTarget }) => setMobileNavbar(currentTarget.parentNode);
  const closeMobileNavbar = () => setMobileNavbar(false);

  useEffect(() => {
    // A function that sets the display state for the DefaultNavbarMobile.
    function displayMobileNavbar() {
      if (window.innerWidth < breakpoints.values.lg) {
        setMobileView(true);
        setMobileNavbar(false);
      } else {
        setMobileView(false);
        setMobileNavbar(false);
      }
    }

    /** 
     The event listener that's calling the displayMobileNavbar function when 
     resizing the window.
    */
    window.addEventListener("resize", displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileNavbar);
  }, []);

  return (
    <Container>
      <MDBox
        py={1}
        // px={{ xs: 4, sm: transparent ? 2 : 3, lg: transparent ? 0 : 2 }}
        // my={3}
        // mx={3}
        // width="calc(100% - 48px)"
        // borderRadius="lg"
        shadow={transparent ? "none" : "md"}
        color={light ? "white" : "dark"}
        display="flex"
        justifyContent="space-around"
        alignItems="center"
        position="absolute"
        left={0}
        right={0}
        zIndex={3}
        sx={({
          palette: { transparent: transparentColor, white, background },
          functions: { rgba },
        }) => ({
          backgroundColor: transparent
            ? transparentColor.main
            : rgba(darkMode ? background.sidenav : white.main, 0.8),
          backdropFilter: transparent ? "none" : `saturate(200%) blur(30px)`,
        })}
        style={{ position: "fixed" }}
      >
        <MDBox
          component="img"
          src={masterCardLogo}
          alt="master card"
          // width="100%"
          height="68px"
          borderRadius="lg"
          coloredShadow="info"
          marginLeft="4px"
        />
        <MDBox color="inherit" display={{ xs: "none", lg: "flex" }} m={0} p={0}>
          {/* <DefaultNavbarLink icon="donut_large" name="admin" route="/admin" light={light} /> */}

          {auth ? (
            <MDBox>
              <DefaultNavbarLink
                icon="logout_icon"
                name="ログアウト"
                func={() => {
                  removeCookie(STORAGEKEY.ACCESS_TOKEN);
                  removeCookie(STORAGEKEY.REFRESH_TOKEN);
                  if (dataUser?.role === ROLE_USER.USER) {
                    navigate("/login");
                  } else {
                    navigate("/admin");
                  }
                }}
                light={light}
              />
            </MDBox>
          ) : (
            <>
              <DefaultNavbarLink
                icon="account_circle"
                name="新規登録"
                // route="/register"
                func={() => navigate("/register")}
                light={light}
              />
              <DefaultNavbarLink
                icon="key"
                name="ログイン"
                func={() => navigate("/login")}
                light={light}
              />
            </>
          )}
        </MDBox>
        <MDBox
          display={{ xs: "inline-block", lg: "none" }}
          lineHeight={0}
          py={1.5}
          pl={1.5}
          color="inherit"
          sx={{ cursor: "pointer" }}
          onClick={openMobileNavbar}
        >
          <Icon fontSize="default">{mobileNavbar ? "close" : "menu"}</Icon>
        </MDBox>
      </MDBox>
      {mobileView && <DefaultNavbarMobile open={mobileNavbar} close={closeMobileNavbar} />}
    </Container>
  );
}

// Setting default values for the props of DefaultNavbar
DefaultNavbar.defaultProps = {
  transparent: false,
  light: false,
};

// Typechecking props for the DefaultNavbar
DefaultNavbar.propTypes = {
  transparent: PropTypes.bool,
  light: PropTypes.bool,
};

export default DefaultNavbar;
