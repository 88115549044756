import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { Controller } from "react-hook-form";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import "moment/locale/ja";
import { DateTimePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { jaJP } from "@mui/x-date-pickers/locales";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";

function DateTimePickerController({
  name,
  control,
  label,
  disabled = false,
  grid = { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
}) {
  const { t } = useTranslation();

  return (
    <Grid item xs={grid.xs} sm={grid.sm} md={grid.md} lg={grid.lg} xl={grid.xl}>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <MDBox mb={2}>
            <LocalizationProvider
              dateAdapter={AdapterMoment}
              adapterLocale="ja"
              localeText={jaJP.components.MuiLocalizationProvider.defaultProps.localeText}
            >
              <DateTimePicker
                slotProps={{
                  toolbar: {
                    toolbarFormat: "MM月 DD日",
                  },
                }}
                localeText={{
                  toolbarTitle: t("common.dateTimePicker.toolbarTitle"),
                  fieldDayPlaceholder: () => "dd",
                  fieldMonthPlaceholder: () => "mm",
                  fieldYearPlaceholder: () => "yyyy",
                  fieldHoursPlaceholder: () => "hh",
                }}
                onError={(newError) => {
                  if (newError === "maxDate" || newError === "maxTime") {
                    onChange(moment());
                  }
                }}
                label={label}
                onChange={onChange}
                value={value}
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root": {
                    paddingTop: "2px",
                  },
                }}
                // maxDate={moment()}
                // maxTime={moment()}
                maxDateTime={moment()}
                disabled={disabled}
              />
            </LocalizationProvider>
            {error?.message && (
              <MDTypography variant="caption" color="error">
                {error?.message}
              </MDTypography>
            )}
          </MDBox>
        )}
      />
    </Grid>
  );
}
export default DateTimePickerController;
